var util = window.components.Util;
var progress = window.components.Progress;

function convertPriceByCurrency(basePrice) {
    let price = parseFloat(Math.round(basePrice * 100) / 100);
    let currentCurrencyCode = util.getCookie('currencycode');

    if (typeof currencyConfig !== 'undefined' && !$.isEmptyObject(currencyConfig) && typeof OSREC !== 'undefined') {
        let exchangeRate = isNaN(currencyConfig.currencies[currentCurrencyCode].exchangeRate) ? 1 : currencyConfig.currencies[currentCurrencyCode].exchangeRate;
        let currencyPattern = currencyConfig.currencies[currentCurrencyCode].currencyPattern;
        let currencyFormatterOptions = {
            currency: currentCurrencyCode,
            pattern: currencyPattern
        };

        let convertedPrice = OSREC.CurrencyFormatter.format(price * exchangeRate, currencyFormatterOptions);
        return convertedPrice;
    }

    // Convert to default site currency by default
    if (SitePreferences.SITEID === "ss-au" && currentCurrencyCode === "AUD") {
        return "$" + price;
    } else if (SitePreferences.SITEID === "ss-nz" && currentCurrencyCode === "NZD") {
        return "$" + price + " NZD";
    }

    return "";
}

function removeReservedCartItems(productIDs) {
    for (let i = 0; i < productIDs.length; i++) {
        $(".reserved-cart__item[data-product-id='" + productIDs[i] + "']").remove();
    }

    updateReservedCartTotal();
}

function updateReservedCartTotal() {
    let $reservedCartItems = $(".reserved-cart__item-list").find(".reserved-cart__item");
    let totalValue = 0;

    $reservedCartItems.each(function(){
        let $row = $(this);
        let $salesPrice = $row.find(".item-price .price-sales");
        let priceValue = ($salesPrice.length > 0) ? $salesPrice.data("price-value") : 0;

        let qtyValue = $row.find('.item-quantity input[name="quantity"]').val();
        qtyValue = isNaN(qtyValue) ? 1 : parseInt(qtyValue);

        totalValue += priceValue * qtyValue;
    });

    let convertedPrice = convertPriceByCurrency(totalValue);
    if (convertedPrice) {
        $(".reserved-cart-totals .order-value").html(convertedPrice);
    }
}

function showError(errorProducts) {
    for (let i = 0; i < errorProducts.length; i++) {
        let $reservedCartItem = $(".reserved-cart__item[data-product-id='" + errorProducts[i] + "']");

        if ($reservedCartItem.length > 0) {
            $reservedCartItem.find(".item-details .item-error-message").html(Resources.CART_RESERVATION_ADD_TO_CART_ERROR);
        }
    }
}

function clearError() {
    var $reservedCartItems = $(".reserved-cart__item-list").find(".reserved-cart__item");

    $.each($reservedCartItems, function() {
        $(this).find(".item-details .item-error-message").html("");
    });
}

module.exports.init = function() {
    var $reservedCartItems = $(".reserved-cart__item-list").find(".reserved-cart__item");
    var $reservedCartActions = $(".reserved-cart__actions");
    var continueShoppingURL = $reservedCartActions.find(".continue-shopping").data("continue-shopping");

    $reservedCartItems.find(".reserved-cart__delete-item").on("click", function(e) {
        e.preventDefault();

        removeReservedCartItems([$(this).data("product-id")]);
        updateReservedCartTotal();

        if ($(".reserved-cart__item-list").find(".reserved-cart__item").length == 0) {
            let $td = $("<td>").attr("colspan", 6)
                               .text("Your Reserved Cart is Empty");

            let $row = $("<tr>").attr("class", "reserved-cart__item--empty")
                                .append($td);

            $(".reserved-cart__item-list").find("tbody").append($row);
            $(".add-to-cart").attr("disabled", "disabled");

            window.location.href = continueShoppingURL;
            return;
        }
    });

    $reservedCartItems.find('.item-quantity input[name="quantity"]').on("change", function(e) {
        e.preventDefault();
        let $this = $(this);

        if ($this.length === 0 || !$this.val() || isNaN($this.val()) || parseInt($this.val(), 10) === 0 || $this.val() <= 0) {
            $this.val("1");
        }

        let qtyVal = $this.val();
        let $item = $this.closest(".reserved-cart__item");
        let $salesPrice = $item.find(".item-price .price-sales");
        let priceValue = ($salesPrice.length > 0) ? $salesPrice.data("price-value") : 0;

        let $itemPriceTotal = $item.find(".price-total");
        $itemPriceTotal.html(convertPriceByCurrency(priceValue * qtyVal));

        updateReservedCartTotal();
    });

    $reservedCartActions.find(".add-to-cart").on("click", function(e) {
        e.preventDefault();
        progress.show($("#main"));

        let products = [];
        let $reservedCartItems = $(".reserved-cart__item-list").find(".reserved-cart__item");

        $reservedCartItems.each(function() {
            let productID = $(this).data("product-id").toString();
            let qtyVal = $(this).find('.item-quantity input[name="quantity"]').val();

            products.push({
                pid: productID,
                quantity: isNaN(qtyVal) ? 1 : parseInt(qtyVal)
            });
        });

        $.ajax({
            type: 'POST',
            dataType: 'json',
            cache: false,
            contentType: 'application/json',
            url: Urls.addReservedProductsToCart,
            data: JSON.stringify(products)
        })
        .done(function (response) {
            if (!response.success) {
                showError(response.errorProducts);
            } else {
                clearError();
            }

            $(document).trigger("minicart::update", { open: true });
        })
        .fail(function (xhr, textStatus) {
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        })
        .always(function () {
            progress.hide();
        });
    });

    $reservedCartActions.find(".continue-shopping").on("click", function(e) {
        e.preventDefault();
        window.location.href = continueShoppingURL;
    });
}