(function() {
    var instance = {};

    instance.components = {
        select2: {
            selectDropdown: "select"
        },
        swiper: {
            navigationPrev: ".swiper-prev",
            navigationNext: ".swiper-next",
            slide: ".swiper-slide",
            swiperContainer: ".swiper-container",
            swiperWrapper: ".swiper-wrapper",
            swiperPagination: ".swiper-pagination",
            swiperPaginationSwitch: ".swiper-pagination-switch",
            swiperPaginationVisibleSwitch: ".swiper-visible-switch"
        }
    };
    module.exports = instance;
}());
