var _ = window.components._;

const DELAY = 300;

function initializeEvents() {
    var $brandContainer = $('.brand-container');

    if ($brandContainer.length > 0) {
        var $brandSearchInput = $brandContainer.find('.brand-search__input');
        var $brandGroupList = $brandContainer.find('.brand-group-list');

        var debounceListFilter = _.debounce(listFilter, DELAY);

        $brandSearchInput.on('keyup', function () {
            debounceListFilter($brandGroupList, $.trim($(this).val()).toLowerCase());
        });

        $brandGroupList.on('click', '.brand-group', function (e) {
            $(e.currentTarget.querySelector('.brand-group__link')).slideToggle(DELAY);
        });
    }
}

function listFilter($brandGroupList, filterValue) {
    var $brandGroups = $brandGroupList.find('.brand-group');
    var $brandNohitsError = $('.brand__nohits-error');
    var searchTotalHits = 0;

    $.each($brandGroups, function () {
        var $brandGroup = $(this);
        var $brandGroupItems = $brandGroup.find('.brand-group__item');
        var $brandGroupInput = $brandGroup.children('input');

        if (filterValue != '') {
            var $filterItems = $brandGroupItems.filter('[data-brand-name*="' + filterValue + '"]');
            searchTotalHits += $filterItems.length;

            if ($filterItems.length <= 0) {
                $brandGroup.slideUp();
                $brandGroupInput.prop('checked', false);
            } else {
                $brandGroup.slideDown();

                var $filterOutItems = $brandGroupItems.filter(':not([data-brand-name*="' + filterValue + '"])');
                $filterOutItems.slideUp();

                $filterItems.slideDown();

                // Expand the brand group only if the search term is at least 2 characters
                if (filterValue.length < 2) {
                    $brandGroupInput.prop('checked', false);
                } else {
                    $brandGroupInput.prop('checked', true);
                }
            }
        } else {
            $brandGroup.slideDown();
            $brandGroupItems.slideDown();
            $brandGroupInput.prop('checked', false);
        }
    });

    if (searchTotalHits <= 0 && filterValue != '') {
        $brandNohitsError.slideDown();
    } else {
        $brandNohitsError.slideUp();
    }
}

var brand = {
    init: function () {
        initializeEvents();
    }
};

module.exports = brand;
