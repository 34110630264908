(function() {
    "use strict";
    function swiperModule(options){
    	var uniform = require('../thirdparty/swiper.jquery.min'),
        selectors = (options && options.selectorsModule) ? options.selectorsModule : require('../resources/resources.selectors'),
        selectorsModules = selectors.components.swiper;

        var instance = {};

        instance.init = function (selector, options) {
            registerSwiperOnSelector(selector, options);
        };

        instance.regularHorizontalSwiperSettings = {
            calculateHeight: true,
            mode: "horizontal",
            resizeReInit: true,
            slidesPerView: "auto",
            visibilityFullFit: true
        };

        var registerSwiperOnSelector = function (selector, options) {
            var $swiperContainers = $(selector);
            if ($swiperContainers.length === 0) {
                return;
            }

            options.slidesPerView = options.slidesPerView || 1;
            options.loop = options.loop || false;
            options.maxItemAvailable = options.maxItemAvailable || false;
            options.paginationArrowsAppendType = options.paginationArrowsAppendType || "append";
            if (options.paginationArrowsAppendType !== "append" && options.paginationArrowsAppendType !== "after") {
                options.paginationArrowsAppendType = "append";
            }


            $swiperContainers.each(function() {
                var swiperContainerObject = this;
                var swiperControl = $(swiperContainerObject).data("swiper");
                if (swiperControl) {
                    return true;
                }

                var swiperSlides = $(swiperContainerObject).find(selectorsModules.slide);

                var swiperOptions = $.extend({}, options);

                // Set Max item available
                if (swiperOptions.maxItemAvailable !== false && swiperOptions.maxItemAvailable > 0) {
                    if (swiperSlides.length > swiperOptions.maxItemAvailable) {
                        for (var i = swiperSlides.length - 1; i >= swiperOptions.maxItemAvailable; i--) {
                            swiperSlides.get(i).remove();
                        }
                    }
                }

                // Don't init the slider if the number of slides is less than or equal the slides per view
                if (swiperOptions.loop && swiperSlides.length <= swiperOptions.slidesPerView) {
                    return;
                }

                // Set Pagination dots
                if (swiperSlides.length <= swiperOptions.slidesPerView) { // don't show pagination dots if it have 1 page
                    swiperOptions.createPagination = false;
                }
                swiperOptions.pagination = $(swiperContainerObject).find(swiperOptions.pagination).get(0);

                // Set Pagination arrows
                var $navigationPrevButton = getNavigationButton(swiperContainerObject, swiperOptions, selectorsModules.navigationPrev);
                var $navigationNextButton = getNavigationButton(swiperContainerObject, swiperOptions, selectorsModules.navigationNext);

                var hasNavArrows = $navigationPrevButton.length > 0 && $navigationNextButton.length > 0;

                if (swiperOptions.paginationArrows && !hasNavArrows) {
                    var navigationPrevHtml = "<div class='swiper-nav " + selectorsModules.navigationPrev.substr(1) + "'></div>";
                    var navigationNextHtml = "<div class='swiper-nav " + selectorsModules.navigationNext.substr(1) + "'></div>";

                    if (swiperOptions.paginationArrowsAppendType === "append") {
                        $(swiperContainerObject).append(navigationPrevHtml);
                        $(swiperContainerObject).append(navigationNextHtml);
                    }
                    else if (swiperOptions.paginationArrowsAppendType === "after") {
                        $(swiperContainerObject).after(navigationPrevHtml);
                        $(swiperContainerObject).after(navigationNextHtml);
                    }
                }

                var slidesPerViewData = $(swiperContainerObject).data("slidesperview");
                if (slidesPerViewData != null) {
                	swiperOptions.slidesPerView = slidesPerViewData;
                }
                var slidesPerGroupData = $(swiperContainerObject).data("slidespergroup");
                if (slidesPerGroupData != null) {
                	swiperOptions.slidesPerGroup = slidesPerGroupData;
                }

                // setup Swiper for container
                var swiper = $(swiperContainerObject).swiper(swiperOptions);
                var $navigationPrevButton = getNavigationButton(swiper.container, swiper.params, selectorsModules.navigationPrev);
                var $navigationNextButton = getNavigationButton(swiper.container, swiper.params, selectorsModules.navigationNext);

                if (swiperOptions.paginationArrows) {
                    $navigationPrevButton.on("click", function(e) {
                        e.preventDefault();
                        if (swiper.params.slidesPerGroup > 1) {
                            swiper.swipeTo(Math.max(0, swiper.activeIndex - swiper.params.slidesPerGroup));
                        } else {
                            swiper.swipePrev();
                        }
                    });

                    $navigationNextButton.on("click", function(e) {
                        e.preventDefault();
                        if (swiper.params.slidesPerGroup > 1) {
                            swiper.swipeTo(swiper.activeIndex + swiper.params.slidesPerGroup);
                        } else {
                            swiper.swipeNext();
                        }
                    });

                }

                if (swiperOptions.pauseOnHover) {
                    registerPauseOnHover(swiperContainerObject);
                }

                /*swiper.addCallback("SlideChangeEnd", updateNavigationButtons);
                swiper.addCallback("SlideReset", updateNavigationButtons);
                swiper.addCallback("Init", updateNavigationButtons);*/
                if (swiperOptions.paginationGrouped) {
                    swiper.addCallback("CreatePagination", initGroupPagination);
                    swiper.addCallback("UpdatePagination", initGroupPagination);
                    initGroupPagination(swiper);
                }

                updateNavigationButtons(swiper);
                preventClickOnSwipe(swiper);
            });
        };

        var updateNavigationButtons = function(swiper) {
            if (swiper.params.loop) {
                return;
            }

            var $slides = $(swiper.wrapper).children(selectorsModules.slide);
            var indexOfActiveSlide = isNaN(swiper.activeIndex) ? 0 : swiper.activeIndex;
            var slidesCount = swiper.slides.length;
            var visibleSlidesCount =  (swiper.visibleSlides != void (0)) ? swiper.visibleSlides.length : slidesCount;


            var $navigationPrevButton = getNavigationButton(swiper.container, swiper.params, selectorsModules.navigationPrev);
            var $navigationNextButton = getNavigationButton(swiper.container, swiper.params, selectorsModules.navigationNext);

            $navigationPrevButton.toggleClass("hidden", slidesCount == visibleSlidesCount);
            $navigationNextButton.toggleClass("hidden", slidesCount == visibleSlidesCount);

            $navigationPrevButton.toggleClass("swiper-nav-disabled", indexOfActiveSlide == 0);
            $navigationNextButton.toggleClass("swiper-nav-disabled", indexOfActiveSlide + visibleSlidesCount >= slidesCount);

        };

        var registerPauseOnHover = function (swiperContainer) {
            $(swiperContainer).hover(function(event){
                var swiperControl = $(swiperContainer).data("swiper");

               if (event.type == "mouseenter") {
                   swiperControl.stopAutoplay();
               }
               else if (event.type == "mouseleave") {
                   swiperControl.startAutoplay();
               }
            });
        };

        var getNavigationButton = function (swiperContainer, swiperOptions, navigationSelector) {
            var $navigations = {};
            if (swiperOptions.paginationArrowsAppendType === "append") {
                $navigations = $(swiperContainer).children(navigationSelector);
            } else if (swiperOptions.paginationArrowsAppendType === "after") {
                $navigations = $(swiperContainer).siblings(navigationSelector);
            }

        	return $navigations;
        }

        var initGroupPagination = function (swiper) {
            var visibleSlidesCount = swiper.params.slidesPerView;
            if (isNaN(visibleSlidesCount)){
            	visibleSlidesCount = swiper.visibleSlides.length;
            }

            var numOfButtons = swiper.paginationButtons.length;
            var indexOfActiveSlide = swiper.activeIndex;
            for (var i = 0; i < numOfButtons; i++) {
            	var $pagingButton = $(swiper.paginationButtons[i]);
            	$pagingButton.removeClass("swiper-pagination-hidden-switch");
                if (i % visibleSlidesCount != 0 && i != indexOfActiveSlide) {
                    $pagingButton.addClass("swiper-pagination-hidden-switch");
                }
            }

            // In case the last page doesn't have enough items for slidesPerView
            var $pagingButtonActive = $(swiper.paginationButtons[indexOfActiveSlide]);
            if (indexOfActiveSlide % visibleSlidesCount != 0) {
            	$pagingButtonActive.siblings(selectorsModules.swiperPaginationVisibleSwitch).addClass("swiper-pagination-hidden-switch");
            }

            var $groupPaginationButtons = $(selectorsModules.swiperPaginationSwitch + ":visible");
            if ($groupPaginationButtons.length == 1) {
            	$groupPaginationButtons.addClass("swiper-pagination-hidden-switch");
            }
        }

        /**
         * Prevent unwanted clicks on links during swiping
         */
        var preventClickOnSwipe = function (swiper) {
            swiper.params.custom = {
                swiped: false
            };

            var swipeHandler = function () {
                this.params.custom.swiped = true;
            };
            var tapHandler = function () {
                this.params.custom.swiped = false;
            };

            var hammer = new Hammer(swiper.wrapper.context);
            hammer.on('swipe', swipeHandler.bind(swiper));
            hammer.on('tap', tapHandler.bind(swiper));

            var clickHandler = function (e) {
                if (!e.data) {
                    return;
                }

                if (e.data.swiper && e.data.swiper.params.custom.swiped) {
                    e.preventDefault();
                }

                e.data.swiper.params.custom.swiped = false;
            };

            var $links = $(swiper.wrapper).children(selectorsModules.slide).find('a');
            $links.on('click', { swiper: swiper }, clickHandler);
        }

        return instance;
    }


    module.exports = swiperModule;
})();
