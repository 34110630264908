function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

module.exports.init = function() {
    try {
        if (!SitePreferences.ENABLE_PARTNERIZE) {
            return;
        }

        var clickRef = getUrlParameter("clickref");
        if (clickRef) {
            var exdays = SitePreferences.PARTNERIZE_COOKIE_MAX_AGE_DAYS || 30;
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires="+ d.toUTCString();

            document.cookie = "partnerizeAffiliates=" + clickRef + ";" + expires + ";path=/";
        }
    } catch (err) {
        console.log(err.message);
    }
};