"use strict";

var cyclerSelector = window.components.Selectors.components.cycler,
	swiperModule = window.components.SwiperModule;

function initializeEvents() {
	$('body').on('click', '.toggle-category-icon h3', function () {
        var display = $(this).hasClass('expanded');

        $(this)
            .toggleClass('expanded')
            .parent('.toggle-category-icon')
                .toggleClass('is-expanding').end()
            .siblings('.toggle-content')
                .toggle(!display);
    });
}
exports.init = function(){
	swiperModule.init(cyclerSelector.swiperContainerProduct, {
    	nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        pagination: '.swiper-pagination',
        paginationType: 'fraction',
        paginationClickable: true,
        preventClicksPropagation: false,
        preventClicks: false,
        spaceBetween: 30,
        autoHeight: false,
        breakpoints: {
            767: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetweenSlides: 15
            }
        },
        paginationFractionRender: function (swiper, currentClassName, totalClassName) {
            return '<span class="' + currentClassName + '"></span>' +
                   ' of ' +
                   '<span class="' + totalClassName + '"></span>';
        },
        onInit: function(swiper) {
            var slidePerView = swiper.container.data('slidesperview');

            if(slidePerView && swiper.slides.length <= slidePerView) {
                swiper.container.find('.swiper-controls').hide();
            }
        }
    });

	swiperModule.init(cyclerSelector.swiperContainerCTA, {
    	nextButton: '.swiper-cta-button-next',
        prevButton: '.swiper-cta-button-prev',
        paginationClickable: true,
        preventClicksPropagation: false,
        preventClicks: false,
        spaceBetween: 30,
        autoHeight: false,
        breakpoints: {
            767: {
                slidesPerView: 1,
                spaceBetweenSlides: 15
            }
        }
    });

	initializeEvents();
};
