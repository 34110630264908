(function($, window, undefined) {
	//The Interchange plugin with jQuery
	//options:
	// * data-src: the src will apply when container's width is smaller than the size
	//			   format: "480|image-sm.jpg;768|image-md.jpg;1024|image-lg.jpg"
	// * container: container of image
	// * type: image or background-image (with browser support)
	// * preloading: true/false
	// * default-src: dafault image source, if type is image, it is attribute "src" of img element

  	var updateImageSrc = function(plugin){
	  	var matchedKey = 0;
	  	var matchedValue = null;
	  	var newSrc = null;
	  	var containerWidth = $(plugin.options.container).width();

		for (var i = 0, len = plugin.breakpoints.length; i < len; i++) {
		  	var breakpoint = plugin.breakpoints[i];
		  	$.each(breakpoint, function(key, value) {
		  		if (containerWidth < key && (matchedKey === 0 || key < matchedKey)) {
		  			matchedKey = key;
		  			matchedValue = value;
		  		}
		  	});
	  	}

	  	if (matchedKey > 0) {
	  		newSrc = matchedValue;
	  	} else {
	  		newSrc = plugin.defaultSrc;
	  	}

		if (plugin.options.type === 'image') {
	    	plugin.element.attr('src', newSrc);
	    } else {
	    	plugin.element.css('backgroundImage', 'url('+ newSrc +')');
	    }
	 };

  	function Interchange(element, options) {
    	this.element = $(element);
    	this.options = $.extend({}, $.fn.interchange.defaults, this.element.data(), options);
    	this.init();
  	}

	Interchange.prototype = {
		init: function (){
		  	var that = this;
		  	var dataSrcAttr = that.element.attr('data-src');

		  	if (dataSrcAttr == null) {
		  		return;
		  	}

		  	this.element.attr('src', '');
		  	this.defaultSrc = this.element.attr('src') || this.element.attr('data-default-src');
			this.breakpoints = [];

			var dataImgSrc = dataSrcAttr.replace(/ /g, '').split(';');
			var preloading = [];
			var i, len;

			preloading.push(this.defaultSrc);

			for (i = 0, len = dataImgSrc.length; i < len; i++) {
				var item = dataImgSrc[i].split('|');
				var breakpoint = {};
				breakpoint[item[0]] = item[1];
				this.breakpoints.push(breakpoint)
				preloading.push(item[1]);
			}

			if (this.options.preloading) {
				for (i = 0, len = preloading.length; i < len; i++) {
		  			var image = new Image();
					image.src = preloading[i];
		  		}
			}

			that.update();
		},

		update: function (){
			if (this.breakpoints.length) {
		    	updateImageSrc(this);
			}
		}
	};

	$.fn.interchange = function(options, params) {
		return this.each(function() {
		  	var instance = $.data(this, 'interchange');
		  	if (!instance) {
		    	$.data(this, 'interchange', new Interchange(this, options));
		  	} else if (instance[options]) {
		    	instance[options](params);
		  	}
		});
	};

	$.fn.interchange.defaults = {
		container: window,
		type: 'image', 		//option: [image | background-image]
		preloading: false
	};

	$(function() {
	    $('[data-interchange]').interchange();
	});

}(jQuery, window));


exports.init = function (){
	$('.image-responsive').interchange();
	
	$(window).on('resize.interchange', function(){
		$('.image-responsive').interchange('update');
	});
};