function ImageCarousel(element) {
    if (!element) {
        return;
    }

    let $element = $(element);
    let config = $element.attr("data-config");
    let dotsContainer = element.querySelector(".slides-dots");
    let slidesContainer = element.querySelector(".slides-container");
    let currentSlide = 0;
    let lastSlide = slidesContainer.children.length - 1;
    let autoCycleInterval = null;
    let hammer = new Hammer(slidesContainer);

    if (config) {
        config = JSON.parse(config);

        if (config.autoCycleEnabled) {
            startAutoCycle();
        }
    }

    element
        .querySelector(".slides-next")
        .addEventListener("click", function () {
            clearAutoCycle();
            next();
        });

    element
        .querySelector(".slides-prev")
        .addEventListener("click", function () {
            clearAutoCycle();
            prev();
        });

    $(dotsContainer)
        .children('button')
        .each(function (index, dot) {
            $(dot).click(function () {
                setSlide($(dot).attr("data-slide-index"));
                clearAutoCycle();
            });
        });

    hammer.on("swipeleft", function (event) {
        event.preventDefault();
        clearAutoCycle();
        next();
    });

    hammer.on("swiperight", function (event) {
        event.preventDefault();
        clearAutoCycle();
        prev();
    });

    hammer.on("panstart", function () {
        $(slidesContainer).addClass("slides-container--dragging");
    });

    hammer.on("panend pancancel", function () {
        $(slidesContainer).removeClass("slides-container--dragging");
    });

    function startAutoCycle() {
        if (config.autoCycleEnabled && config.autoCycleDelay > 0) {
            clearInterval(autoCycleInterval);
            autoCycleInterval = setInterval(next, config.autoCycleDelay);
        }

        $(dotsContainer).addClass("slides-dots--autocycle");
    }

    function clearAutoCycle() {
        if (autoCycleInterval) {
            clearInterval(autoCycleInterval);
        }

        $(dotsContainer).removeClass("slides-dots--autocycle");
    }

    function setSlide(index) {
        index = +index;

        if (index > lastSlide) {
            currentSlide = lastSlide;
        } else {
            currentSlide = index;
        }

        _updateSlide();
    }

    function next() {
        currentSlide++;

        if (currentSlide > lastSlide) {
            currentSlide = 0;
        }

        _updateSlide();
    }

    function prev() {
        currentSlide--;

        if (currentSlide < 0) {
            currentSlide = lastSlide;
        }

        _updateSlide();
    }

    function _updateSlide() {
        let transform = (currentSlide * -100) + "%";
        slidesContainer.style.transform = "translate3d(" + transform + ", 0px, 0px)";
        $(slidesContainer).removeClass("slides-container--dragging");

        _updateDots();
    }

    function _updateDots() {
        let dots = dotsContainer.getElementsByTagName("*");

        for (let i = 0; i < dots.length; i++) {
            dots[i].className = "";

            if (i === currentSlide) {
                dots[i].className = "active";
            }
        }
    }
}

module.exports = ImageCarousel;
