'use strict';
var progress = window.components.Progress,
	util = window.components.Util;

var currentRequests = [];

var ajax = require('../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/ajax');

var load = function (options) {
    options.url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url || currentRequests[options.url]) {
        return;
    }

    currentRequests[options.url] = true;

    // make the server call
    $.ajax({
        dataType: 'html',
        url: util.appendParamToURL(options.url, 'format', 'ajax'),
        data: options.data,
        method: options.method || 'GET',
        xhrFields: {
            withCredentials: true
        }
    })
    .done(function (response) {
        // success
        if (options.target) {
            $(options.target).empty().html(response);
        }
        if (options.callback) {
            options.callback(response);
        }
    })
    .fail(function (xhr, textStatus) {
        // failed
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
        }
        options.callback(null, textStatus);
    })
    .always(function () {
        progress.hide();
        // remove current request from hash
        if (currentRequests[options.url]) {
            delete currentRequests[options.url];
        }
    });
};

ajax.load = load;

module.exports = ajax;
