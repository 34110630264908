window.components = window.components || {};

window.components._ = require('lodash');

// Globally dependent components
window.components.Dialog = require("./dialog");
window.components.Progress = require("./progress");
window.components.Selectors = require("./resources/resources.selectors");
window.components.Util = require("./util");
window.components.Validator = require("./validator");

// Inherited components
window.components.AnalyticEvent = require("../../../../../inherited/amblique/int_google/cartridge/src/js/analytic-event");
window.components.TS2AnalyticEvent = require("../../../../../integrations/int_tradestate2/cartridge/src/js/gtm-ts2");
window.components.Tls = require("../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/tls");
window.components.SwiperModule = require("../../../../../inherited/amblique/amblique_common_jscomponents/cartridge/src/js/components/component.swiper")(
    { selectorsModule: window.components.Selectors }
);

// Core components
window.components.Tooltip = require("./tooltip");
window.components.NumbericInput = require("./components/component.numbericinput")();
window.components.ProfileBirthdaySelects = require("./components/component.profile.birthdayselects");
window.components.Newsletter = require("./newsletter");
window.components.Brand = require("./pages/brand");
window.components.Cycler = require("./components/component.cycler");
window.components.Imageresponsive = require("./imageresponsive");
window.components.Currency = require("./currency");
window.components.LazyLoading = require("./lazy-loading");
window.components.Customer = require("./customer");
window.components.CartReservation = require("./cart-reservation");

window.components.PartnerizeClickTracking = require("../../../../../integrations/int_partnerize/cartridge/src/js/partnerizeClickTracking");

var ImageCarousel = require("./components/component.image-carousel");

// Initialize Jquery Plugins
require("../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/jquery-ext")();
require("../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/cookieprivacy")();
require("../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/captcha")();
require("./subcategoryicon")();


function initializeEvents() {
    // subscribe email box
    var $subscribeEmail = $(".subscribe-email");
    if ($subscribeEmail.length > 0) {
        $subscribeEmail
            .focus(function() {
                var val = $(this.val());
                if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                    return; // do not animate when contains non-default value
                }

                $(this).animate({ color: "#999999" }, 500, "linear", function() {
                    $(this)
                        .val("")
                        .css("color", "#333333");
                });
            })
            .blur(function() {
                var val = $.trim($(this.val()));
                if (val.length > 0) {
                    return; // do not animate when contains value
                }
                $(this)
                    .val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                    .css("color", "#999999")
                    .animate({ color: "#333333" }, 500, "linear");
            });
    }

    // add analytic events for brand click on footer
    $(window.components.Selectors.components.footer.footerBrands).on("click", function() {
        window.components.AnalyticEvent.addTrackBrandPageLinks(
                window.components.AnalyticEvent.TRACK_BRAND_PAGE_LINKS.eventAction.footer,
            this.href
        );
    });

    $(window.components.Selectors.components.currency.exchangeRateDisclaimer).on("click", function() {
        window.components.Dialog.open({
            url: $(this).attr("href")
        });
    });
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $("html").addClass("js");
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $("html").addClass("infinite-scroll");
    }
    // load js specific styles
    window.components.Util.limitCharacters();
}

function detectBrowser() {
    var browser;
    var ua = navigator.userAgent.match(
        /(opera|chrome|safari|firefox|mozilla|msie)\/?\s*(\.?\d+(\.\d+)*)/i
    );

    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
        browser = "msie";
    } else if (ua && ua[1] && (typeof ua[1] === "string")) {
        browser = ua[1].toLowerCase();
    } else {
        browser = "unknown";
    }

    return browser;
}

function initializeBody() {
    if (window.components.Util.isMobile()) {
        $("body").addClass(" device mobile");
    }

    if (window.components.Util.isTablet()) {
        $("body")
            .removeClass(" device mobile")
            .addClass(" device tablet");
    }

    $("body").addClass(detectBrowser());
}

function verifyGoogleReCaptcha(token, action, formObj) {
    let params = {};
    params.gReCaptchaResponseToken = token;
    params.gReCaptchaResponseAction = action;

    var url = Urls.verifygooglerecaptcha;

    $.ajax({
        url: url,
        method: 'post',
        data: params,
        success: function (data) {
            var errorMsgEleIdSuffix = action ? '-' + action : '';
            var errorMsgEleId = 'greCaptchMsg' + errorMsgEleIdSuffix;
            
            if (data.status == 'ERROR') {
                $('#' + errorMsgEleId).remove();
                $('<div id="' + errorMsgEleId + '" class="recaptcha-message error">' + data.message + '</div>').insertBefore($(formObj));
                $(formObj).find('span.error').hide();

                // if the formObj = select items form in return, open the accordion where the error is displayed.
                if ($(formObj).attr('id') === 'returns-select-items-form') {
                    $('.headingTwo.accordion-button').trigger('click');
                    $('body').animate({
                        scrollTop: $('.headingTwo.accordion-button').offset().top
                    }, 2000);
                }

                return false;
            } else {
                $('#' + errorMsgEleId).remove();
                $(formObj).find('span.error').show();
                isCaptchaValidated = true;
                $(formObj).find('button[type="submit"]').trigger('click');
            }
        }
    });
}

function initSelect2Control() {
    try{
        $('select.select2').each(function() {
            var $selectComponent = $(this);
            if ($selectComponent.hasClass("no-select2")) {
                return true;
            }
            var placeholderText = $selectComponent.attr("data-phtext");

            if (placeholderText === undefined) {
                placeholderText = "";
            }
            var instance= {};
            var options = {
                minimumResultsForSearch: -1,
                placeholder: placeholderText,
                shouldFocusInput: function (instance) {
                    return true;
                }
            }
            $selectComponent.select2(options);
            $selectComponent.on("change", function(e) {
                $(this).trigger("blur");
            });
            $selectComponent.removeAttr("title");
        });
    }
    catch(er){
        console.log(er.message);
    }
}

window.components.verifyGoogleReCaptcha = verifyGoogleReCaptcha;


var app = {
    init: function() {
        if (document.cookie.length === 0) {
            $("<div/>")
                .addClass("browser-compatibility-alert")
                .append(
                    $("<p/>")
                        .addClass("browser-error")
                        .html(Resources.COOKIES_DISABLED)
                )
                .appendTo("#browser-check");
        }

        window.components.Imageresponsive.init();
        initializeDom();
        initializeBody();
        initializeEvents();

        // client codes
        window.components.SwiperModule.init();
        initSelect2Control();
        window.components.NumbericInput.init();

        // init specific global components
        window.components.Currency.init();
        window.components.ProfileBirthdaySelects.init();

        window.components.Tooltip.init();
        window.components.Validator.init();

        window.components.Newsletter.init();
        window.components.CartReservation.init();

        var imgCarousels = document.querySelectorAll(".ss-image-carousel");
        for (let i = 0; i < imgCarousels.length; i++) {
            new ImageCarousel(imgCarousels[i]);
        }

       window.components.LazyLoading.init();

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            window.components.Tls.getUserAgent();
        }

        window.components.Cycler.init();

        window.blogListMasonry = $(".blog-list").imagesLoaded(function() {
            // Return gutter width based on browser/device width
            function returnGutterWidth() {
                var browserWidth = $(window).width();

                return browserWidth <= 1024 && browserWidth > 767 ? 12 : 24;
            }

            var gutterWidth = returnGutterWidth(),
                masonryOption = {
                    itemSelector: ".blog",
                    columnWidth: ".blog",
                    gutter: gutterWidth,
                    percentPosition: true
                };

            // Get called on every resize event
            $(window).on("resize", function() {
                // Create new option object based on defined variable
                // then assign new gutter width
                var newMasonryOpt = Object.assign({}, masonryOption);
                newMasonryOpt.gutter = returnGutterWidth();

                // re-call masonry
                window.blogListMasonry = $(".blog-list").masonry(newMasonryOpt);
            });

            // Trigger on first load to calculate masonry
            $(window).trigger("resize");
        });

        window.components.Customer.captureWisepopsSubscriptionEmail();
        window.components.PartnerizeClickTracking.init();
    },

    swiperModule: window.components.SwiperModule,
    currency: window.components.Currency
};

window.app = app;

// general extension functions
(function() {
    String.format = function() {
        var s = arguments[0];
        var i,
            len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "gm");
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function() {
    app.init();
});

// Initialize Global JQuery Plugins
require("./plugins/index");

window.modules = window.modules || {};
window.modules.productCycler = require("./modules/product-cycler/product-cycler");

// Initialize Global Components
require("./components/index");

// Initialize Global Integrations
require("./integrations/index");

