module.exports = function Navigation(container) {
    var $shadowOverlay = container.find(".navigation__overlay-shadow");
    var $topCategories = container.find(".navigation__top-categories");

    var getCategoryMenuURL = _.get(window, "data.components.headerNavigation.getCategoryMenuURL");
    var isDropdownOpen = false;

    function openDropdown() {
        $shadowOverlay.addClass("visible");
        isDropdownOpen = true;
    }

    function closeDropdown() {
        container.find(".navigation__top-category-container").removeClass("active");
        $shadowOverlay.removeClass("visible");
        isDropdownOpen = false;
    }

    var getCategoryMenuRequest;
    function openCategoryMenu(categoryID) {
        var $categoryContainer = container.find(
            "#" + categoryID + ".navigation__top-category-container"
        );

        if (!$categoryContainer.hasClass("active")) {
            container.find(".navigation__top-category-container").removeClass("active");
            $categoryContainer.addClass("active");
        }

        if (isDropdownOpen === false) {
            openDropdown();
        }

        var $categoryMenu = $categoryContainer.find(".category-menu");
        if ($categoryMenu.hasClass("loaded")) {
            return;
        }

        if (getCategoryMenuRequest) {
            getCategoryMenuRequest.abort();
        }

        getCategoryMenuRequest = $.ajax({
            method: "GET",
            url: getCategoryMenuURL,
            data: { cgid: categoryID }
        });

        $categoryMenu.addClass("loading-spinner");

        getCategoryMenuRequest.success(function(data) {
            $categoryMenu.removeClass("loading-spinner");
            $categoryMenu.html(data);
            $categoryMenu.addClass("loaded");
        });

        getCategoryMenuRequest.fail(function() {
            $categoryMenu.removeClass("loading-spinner");
        });
    }

    var debouncedOpenCategoryMenu = _.debounce(function() {
        openCategoryMenu(this.id);
    }, 250);

    container
        .find(".navigation__top-category-container")
        .on("mouseenter", debouncedOpenCategoryMenu)
        .on("mouseleave", debouncedOpenCategoryMenu.cancel)
        .on("focusin", function() {
            openCategoryMenu(this.id);
        });

    $topCategories.on("mouseleave", closeDropdown).focusout(function() {
        var $elem = $(this);
        setTimeout(function() {
            // Timeout is required because nothing is focused during focusout event
            var hasFocus = !!($elem.find(":focus").length > 0);
            if (!hasFocus) {
                closeDropdown();
            }
        });
    });

    // Category Menu 03 Tabs Logic
    $topCategories.on("click", ".category-menu-03__tab-heading", function() {
        var $this = $(this);
        var $categoryContent = $this.closest(".category-menu-03__content");

        $categoryContent.find(".category-menu-03__tab-heading").removeClass("active");
        $categoryContent.find(".category-menu-03__tab-content").removeClass("active");

        var tabID = $this.data("tab-id");

        $this.addClass("active");
        $categoryContent.find("[data-for='" + tabID + "']").addClass("active");
    });

    // Category Menu 04 Tabs Logic
    $topCategories.on("click", ".category-menu-04__tab-heading", function() {
        var $this = $(this);
        var $categoryContent = $this.closest(".category-menu-04__content");

        $categoryContent.find(".category-menu-04__tab-heading").removeClass("active");
        $categoryContent.find(".category-menu-04__tab-content").removeClass("active");

        var tabID = $this.data("tab-id");

        $this.addClass("active");
        $categoryContent.find("[data-for='" + tabID + "']").addClass("active");
    });
};
