var _ = window.components._;

function initUnveilPlugin() {
    $.fn.unveil = function(threshold, callback) {
        var $w = $(window),
            th = threshold || 0,
            images = this,
            loaded;

        this.one("unveil", function() {
            if (_lazyLoadImage(this)) {
                if (typeof callback === "function") {
                    callback.call(this);
                }
            }
        });

        function unveil() {
            var inview = images.filter(function() {
                var $e = $(this);
                if ($e.is(":hidden")) return;

                var wt = $w.scrollTop(),
                    wb = wt + $w.height(),
                    et = $e.offset().top,
                    eb = et + $e.height();

                return eb >= wt - th && et <= wb + th;
            });

            loaded = inview.trigger("unveil");
            images = images.not(loaded);
        }

        $w.on("scroll.unveil resize.unveil lookup.unveil", _.throttle(unveil, 100));

        unveil();

        return this;
    };
}

function _lazyLoadImage($elem) {
    var retina = window.devicePixelRatio > 1;
    var attrib = retina ? "data-lazy-src-retina" : "data-lazy-src";
    var cssAttrib = "data-lazy-css";
    var source = $elem.getAttribute(attrib) || $elem.getAttribute("data-lazy-src");
    var cssSource = $elem.getAttribute(cssAttrib);

    if (source) {
        $elem.setAttribute("src", source);
    } else if (cssSource) {
        $($elem).addClass(cssSource);
    } else {
        return null;
    }

    return $elem;
}

module.exports = {
    init: function() {
        initUnveilPlugin();
        var threshold = 200;
        $('[data-lazy-css]').unveil(threshold);
        $('[data-lazy-src]').unveil(threshold);
        $('[data-defer-src]').map(function() {
            var $img = $(this);
            $img.attr('src', $img.data('defer-src'));
        });
    }
};
