'use strict';


var swiperModule = window.components.SwiperModule,
	util = window.components.Util;

module.exports = function () {
	if(util.isMobile()) {
	    swiperModule.init('#cat-icon-swiper-container', {
	        paginationType: 'fraction',
	        slidesPerView: 4,
	        slidesPerGroup: 1,
	        spaceBetween: 0,
	        centeredSlides: true,
	        spaceBetweenSlides: 0,
	        paginationFractionRender: function (swiper, currentClassName, totalClassName) {
	            return '<span class="' + currentClassName + '"></span>' +
	                   ' of ' +
	                   '<span class="' + totalClassName + '"></span>';
	        }
	    });
	    $("#cat-icon-swiper-container").find(".category-icon-desktop").css("transform", "translate3d(-45px, 0px, 0px)");
	}
};
