module.exports = (function ($) {
    $.fn.refs = function () {
        var refObject = {};

        var refs = this.find("[ref]");
        var ref;

        refs.each(function () {
            ref = $(this).attr("ref");

            if (!ref) {
                return;
            }

            if (refObject[ref]) {
                console.error("Duplicate reference found: " + ref);
                return;
            }

            refObject[ref] = this;
        });

        return refObject;
    };
}(jQuery));
