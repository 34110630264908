(function(){
	var baseSelector = require("../../../../../../../src/inherited/amblique/amblique_common_jscomponents/cartridge/src/js/resources/resources.selectors");
	var instance = $.extend(true,{},baseSelector, {
		components:{
			select2:{
				selectDropdown: "select.select2"
			},
			tooltip:{
				container: '.tooltip',
				directionClass: {
					top: 'top',
					right: 'right',
					bottom: 'bottom',
					left: 'left'
				},
				toolTipPosition:{
					top: { my: "center-65 bottom-15", at: "left top" },
					right: { my: "right-15 center+25", at: "left center" },
					bottom: { my: "center+65 top+15", at: "right bottom" },
					left: { my: "left+15 center+25", at: "right center" }
				},
				formFieldTooltip: '.form-field-tooltip .tooltip'
			},
			numbericInput: {
				input: '.quantity input.input-text'
			},
			promotions: {
				accordionContainer: ".promotion-accordion"
			},
			bonusProduct: {
				container: ".bonus-discount-container",
				okButton: ".ok-button",
				cancelButton: ".cancel-button",
				promo: ".bonus-product-promo",
				showDetails: ".show-promo-details",
				details: ".promo-details",
				bonusProductId: "bonus-product-dialog"
			},
			newsletter:{
				formcontainer: "#newsletter-signup-form",
                email: "#newsletter-email",
                mobile: "#newsletter-mobile",
				gender: "#gender-subscription"
			},
			account: {
				userAccount: ".user-account",
				accountFlyout: ".account-flyout",
				toggleFlyout: ".account-flyout-toggle"
			},
			search: {
				searchbutton: "a.search-button",
				searchcontainer: ".header-search",
				searchinput: "input[id='q']",
				hinttext: ".search-hint-text",
				viewallresults: ".search-view-all-results",
				togglesearch: ".toggle-search"
			},
			product: {
				sizeChartId: "size-chart"
			},
			bonusProduct: {
				container: ".bonus-discount-container",
				okButton: ".ok-button",
				cancelButton: ".cancel-button",
				promo: ".bonus-product-promo",
				showDetails: ".show-promo-details",
				details: ".promo-details",
				bonusProductId: "bonus-product-dialog"
			},
			currency: {
				exchangeRateDisclaimer: ".exchange-rate-disclaimer"
			},
			flyoutmenu:{
				headerContainer: '.top-banner',
				navigationContainer : '#navigation',
				menuContainer: '#navigation .menu-category',
				menuItem: '#navigation .menu-category > li > a',
				flyoutContainer: '#navigation .flyout-menu',
				closeBtn: '#navigation .flyout-menu .btn-close',
				brandContainer: '.brand-list',
				searchBrandContainer: '.search-brands',
				searchBrandInput: '.input-search-brand',
				btnBack: '.btn-back-to-brand',
				searchbrandResult: '.search-brand-result',
				categoryLink: '.brands .cat-link',
				mobileMenu:{
					navigationContainer: '#hamburger-menu',
					backLink: '.backlink',
					slideItem: '.menu-slide',
					itemLink: 'a.slide-active, .menu-item-toggle',
					menuContainer: '.menu-category'
				},
				topLevelBrand: '.top-level-brand, .brands .brand-group a.cat-link'
			},
			cycler: {
				swiperContainerProduct: ".swiper-container-product",
				swiperContainerCTA: ".swiper-container-cta",
				swiperSlide: ".swiper-slide",
				selectedSwiperSlide: ".selected.swiper-slide",
				swiperControls: ".swiper-controls",
				swiperButtonNext: ".swiper-button-next",
				swiperButtonPrev: ".swiper-button-prev",
				swiperPagination: ".swiper-pagination"
			},
			courier: {
				suburbInput: ".city-input [name$=_city]:text",
				postCodeInput: ".postal-input [name$=_postal]:text",
				countryInput: ".country-select select[name$=_country]",
	            stateInput: ".regional-hidden [name$=_state]:hidden, .regional-select select:visible, .regional-input :text:visible",
			},
			cart: {
				updateItemLink: ".update-item",
				itemRow: ".cart-row"
			},
			footer: {
				footerBrands: ".footer-brands a:not(.view-all)"
			}
		},
		page: {
            home: {
                pageContainer: ".pt_storefront"
            },
			pdp: {
				afterpayLightboxContainer: ".product-afterpay-message .afterpay-image",
				swatchesColor: ".swatches.color",
				swatchesSwatchColour: ".swatches.swatchcolour",
				colorSwiperWrapper: ".swiper-wrapper",
				colorData: ".color-data",
				brandBreadcrumb: ".breadcrumb .breadcrumb-brand"
			},
			plp: {
				lazyLoadingContainer: ".lazy-loading-container",
				loadingPlaceholder: '.infinite-scroll-placeholder[data-loading-state="unloaded"]',
				searchResultContent: 'div.search-result-content',
				catBanner: "#cat-banner, #cat-top-banner",
				slotCalloutMsg: ".callout-msg",
				clearRefinementLink: ".clear-refinement a",
				refinementLink: ".refinement ul a",
				paginationLink: ".pagination a:not(.page-navigation)",
				paginationCurrent: ".pagination current-page",
				pageNavigationLink: "a.page-navigation",
				nextPageLink: "a.page-next, a.page-last",
				refinementBrand: "a.refinement-brand"
			},
			blog: {
				lazyLoadingContainer: ".lazy-loading-container",
				loadingPlaceholder: '.blog-infinite-scroll-placeholder[data-loading-state="unloaded"]',
				searchResultContent: '.blog-list'
			},
			returns:{
				findOrderForm: '#returns-find-order-form',
				returnDetails: '#selectReturnItems',
				selectReturnType: '.return-type-dropdown-list',
				selectReturnCode: '.replace-reason-dropdown-list',
				selectReturnExchangeSize: '.replace-size-dropdown-list',
                returnEditAddress: '#return-edit-address',
				returnForm: '#returns-select-items-form',
				returnSubmit: '#submit-return',
				returnLineItem: ".line-item",
				returnResult: '#return-result',
				errormessage: '.error-message',
				commentContainer: '#additional-comment-prod-',
				returnsCarrier: '[name=returns-carrier]',
				returnsCompleteContent: '.returns-complete-content',
				raNumberWrapper: '.ra-number-wrapper',
				raNumber: '.ra-number',
				returnInfo: '#return-info',
			}
		}
	});

	module.exports = instance;
}());
