'use strict';

var _ = window.components._,
	dialog = require('../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/dialog');

dialog.createNewInstance = function(){
	var dlg = _.merge({}, dialog, {$container: null});
	return dlg;
}

dialog.create = function (params) {
    var $target, id;

    if (_.isString(params.target)) {
        if (params.target.charAt(0) === '#') {
            $target = $(params.target);
        } else {
            $target = $('#' + params.target);
        }
    } else if (params.target instanceof jQuery) {
        $target = params.target;
    } else {
        $target = $('#dialog-container');
    }

    // if no element found, create one
    if ($target.length === 0) {
        if ($target.selector && $target.selector.charAt(0) === '#') {
            id = $target.selector.substr(1);
            $target = $('<div>').attr('id', id).addClass('dialog-content').appendTo('body');
        }
    }

    // create the dialog
    this.$container = $target;
    this.$container.dialog(_.merge({}, this.settings, params.options || {}));

    var closeDialog = function () {
        $(this).dialog('close');
    };

    this.$container.on('dialogopen', function(event) {
        $('body').addClass('prevent-scroll');

        var _self = this;
        var overlayClass = "ss-" + _self.id;
        $('.ui-widget-overlay').addClass(overlayClass);
        $(document).off('click.' + overlayClass).on('click.' + overlayClass, '.ui-widget-overlay.' + overlayClass, closeDialog.bind(_self));
    });

    this.$container.on('dialogclose', function(event) {
        if (!$('.ui-dialog:visible').length) {
            $('body').removeClass('prevent-scroll');
        }

        $('.ui-widget-overlay').removeClass('ss-' + this.id);
    });
}

module.exports = dialog;
