var util = require('../../util');

module.exports = function RegionPreferences() {
    var $regionPreferences = $("#region-preferences");
    if ($regionPreferences.length < 1) {
        return;
    }

    var $body = $("body");
    var $overlay = $regionPreferences.find(".region-preferences__overlay");
    var $closeButton = $regionPreferences.find(".region-preferences__modal-header button");
    var $form = $regionPreferences.find(".region-preferences__form");
    var $currencySelector = $form.find("#region-currency");
    var $siteSelector = $form.find("#region-website");

    var setSessionCurrencyURL = _.get(
        window,
        "data.components.region-preferences.SetSessionCurrencyURL"
    );
    var changeSiteURL = _.get(window, "data.components.region-preferences.ChangeSiteURL");
    var requestInProgress = false;

    function open() {
        $regionPreferences.addClass("active");
        $body.addClass("scroll-locked");
    }

    function close() {
        $regionPreferences.removeClass("active");
        $body.removeClass("scroll-locked");
    }

    function changeSite(currencyCode, siteID) {
        if (!changeSiteURL || !siteID) {
            console.error("Cannot change site because request parameters are missing.");
            close();
            return;
        }

        if (requestInProgress) {
            console.warn(
                "One region preferences request is already in progress, please wait for it to complete."
            );
            return;
        }

        requestInProgress = $.ajax({
            dataType: "json",
            url: changeSiteURL,
            data: {
                format: "ajax",
                defaultSiteId: siteID,
                currencyCode: currencyCode || ""
            }
        });

        requestInProgress.done(function(data) {
            if (data && data.success) {
                close();

                if (data.serializedCartItems) {
                    let cartReservationURL = _.get(window, "data.components.region-preferences.CartReservationURL");
                    let redirectURL = util.appendParamsToUrl(cartReservationURL, {"reservedcart": data.serializedCartItems});
                    location.assign(redirectURL);
                } else {
                    location.reload(true);
                }
            } else {
                console.error("Unexpected response recieved from Currency controller");
            }

            requestInProgress = undefined;
        });

        requestInProgress.fail(function() {
            console.error("Request to update region preferences failed");
            close();
            requestInProgress = undefined;
        });
    }

    function setSessionCurrency(currencyCode) {
        if (!setSessionCurrencyURL || !currencyCode) {
            console.error("Cannot change site currency because request parameters are missing");
            close();
            return;
        }

        if (requestInProgress) {
            console.warn(
                "One region preferences request is already in progress, please wait for it to complete."
            );
            return;
        }

        requestInProgress = $.ajax({
            dataType: "json",
            url: setSessionCurrencyURL,
            data: {
                format: "ajax",
                currencyMnemonic: currencyCode || ""
            }
        });

        requestInProgress.done(function(data) {
            if (data && data.success) {
                close();
                location.reload(true);
            } else {
                console.error("Unexpected response recieved from Currency controller");
            }

            requestInProgress = undefined;
        });

        requestInProgress.fail(function() {
            console.error("Request to update region preferences failed");
            close();
            requestInProgress = undefined;
        });
    }

    function submit() {
        var currentSiteID = $form.data("current-site-id");
        var currentCurrencyCode = $form.data("current-currency");

        var selectedSiteID = $siteSelector.val();
        var selectedCurrencyCode = $currencySelector.val();

        if (selectedCurrencyCode === currentCurrencyCode && selectedSiteID === currentSiteID) {
            close();
        } else if (
            selectedCurrencyCode !== currentCurrencyCode &&
            selectedSiteID === currentSiteID
        ) {
            setSessionCurrency(selectedCurrencyCode);
        } else {
            changeSite(selectedCurrencyCode, selectedSiteID);
        }
    }

    $overlay.click(close);
    $closeButton.click(close);

    $form.submit(function() {
        event.preventDefault();
        submit();
    });

    $siteSelector.on("change", function() {
        if ($siteSelector.val() === "ss-au") {
            $currencySelector.val("AUD");
        } else if ($siteSelector.val() === "ss-nz") {
            $currencySelector.val("NZD");
        }
    });

    $(document).on("region-preferences::open", open);
    $(document).on("region-preferences::close", close);
};
