var ajax = require('./ajax');

function saveEmailToSession(email) {
    if (!email) return;

    $.ajax({
        method: 'POST',
        url: Urls.saveEmailToSession,
        data: {
            email: email
        }
    });
}

function captureWisepopsSubscriptionEmail() {
    try {
        var wisepopsSignupForm = document.querySelector(".wisepops-popup .wisepops-block-signup > form");

        if (!wisepopsSignupForm) {
            return;
        }

        var signupButton = wisepopsSignupForm.querySelector("button");

        if (!signupButton) {
            return;
        }

        signupButton.addEventListener("click", function() {
            var emailInput = wisepopsSignupForm.querySelector("input[name='email']");
            var genderRadio = wisepopsSignupForm.querySelector("input[name='Gender']");

            if (emailInput.checkValidity() && genderRadio.checkValidity()) {
                saveEmailToSession(emailInput.value);
            }
        });
    } catch(err) {
        console.log("Failed to capture Wisepops subscription email");
    }
}

module.exports.captureWisepopsSubscriptionEmail = captureWisepopsSubscriptionEmail;