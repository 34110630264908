module.exports = function Minicart() {
    var $minicart = $("#minicart");
    var $body = $("body");
    if ($minicart.length < 1) {
        return;
    }

    var initiallyUpdated = false;

    var $contentContainer = $minicart.find(".minicart__content");
    var $overlay = $minicart.find(".minicart__overlay");

    var getMinicartURL = _.get(window, "data.components.minicart.getMinicartURL");
    var getMinicartRequest;

    function open() {
        if (initiallyUpdated) {
            $minicart.addClass("active");
            $body.addClass("scroll-locked");
        }
    }

    function update(callback, shouldOpen) {
        if (!getMinicartURL) {
            console.error("getMinicartURL is missing, unable to update minicart");
            return;
        }

        getMinicartRequest = $.ajax({
            method: "GET",
            url: getMinicartURL
        });

        getMinicartRequest.success(function(data) {
            $contentContainer.html(data);

            // one tick timeout is required for html content above to be placed into the dom correctly
            setTimeout(function() {
                var $content = $contentContainer.find("#mini-cart-content");
                $content.find(".mini-cart-collapse").on("click", close);
                
                var $accordion = $contentContainer.find(".adc-accordion");
                if ($accordion.length) {
                    var $icon = $accordion.find(".ss-icon");
                    $accordion.find(".adc-accordion__header").on("click", function() {
                        if ($accordion.hasClass("adc-accordion--collapsed")) {
                            $accordion.removeClass("adc-accordion--collapsed").addClass("adc-accordion--expanded");
                            $icon.removeClass("ss-plus").addClass("ss-minus");
                            $accordion.find(".adc-accordion__body").slideDown();
                        } else {
                            $accordion.removeClass("adc-accordion--expanded").addClass("adc-accordion--collapsed");
                            $icon.removeClass("ss-minus").addClass("ss-plus");
                            $accordion.find(".adc-accordion__body").slideUp();
                        }
                    });
                }

                initializeBonusSelectionEvent();

                var productCount;
                if ($content.length < 1) {
                    productCount = 0;
                } else {
                    productCount = $content.data("product-count");
                }

                $(document).trigger("minicart::update-product-count", productCount);
                $(document).trigger("minicart::update-complete", initiallyUpdated);

                if (shouldOpen) {
                    open();
                }

                if (callback) {
                    callback();
                }
            });
        });

        getMinicartRequest.fail(function() {
            console.error("Failed to update minicart");
        });
    }

    function close() {
        $minicart.removeClass("active");
        $body.removeClass("scroll-locked");
    }

    function initializeBonusSelectionEvent() {
        $minicart.find(".select-bonus").click(function(event) {
            event.preventDefault();
            var UUID = $(this).data("bonus-uuid");
    
            close();
            $(document).trigger("bonus-product-modal::open", UUID);
        });
    }

    update(function() {
        initiallyUpdated = true;
    });

    initializeBonusSelectionEvent();

    $(document).on("minicart::open", open);
    $(document).on("minicart::update", function(event, params) {
        if (params) {
            update(params.callback, params.open);
        } else {
            update();
        }
    });

    $overlay.on("click", close);
};
