var ajax = require("../../ajax"),
    util = require("../../util"),
    selectorModule = require("../../resources/resources.selectors"),
    selector = selectorModule.components.flyoutmenu;

var menuflyout = {
    initEvent: function() {
        var headerContainer = $(selector.headerContainer),
            menuTimer;

        function closeMenu($flyoutContainer) {
            if (!$flyoutContainer.is(":visible")) {
                $(".menu-overlay").remove();
            }

            $(selector.flyoutContainer)
                .not($flyoutContainer)
                .hide();
            $flyoutContainer.trigger("megamenu:close", [$flyoutContainer]);
        }

        $(selector.closeBtn).click(function(e) {
            e.stopPropagation();
            closeMenu(
                $(this)
                    .parent()
                    .children(selector.flyoutContainer)
            );
        });

        var lastElement = null;
        function showMenu($flyoutContainer) {
            if ($flyoutContainer.length) {
                $(selectorModule.components.select2.selectDropdown).select2("close");
                $flyoutContainer.show();
                $flyoutContainer.trigger("megamenu:open", [$flyoutContainer]);

                var $menuOverlay = $(".menu-overlay");
                if (!$menuOverlay.length) {
                    $menuOverlay = $(
                        "<div class='menu-overlay' style='position: fixed;top: 0;left: 0;width: 100%;height: 100%; background: rgba(0,0,0,0.3); overflow: visible; z-index: 10;'></div>"
                    ).appendTo(headerContainer);
                }

                $menuOverlay.click(function(e) {
                    e.stopPropagation();
                    $(".menu-overlay").remove();
                    $(selector.flyoutContainer).hide();
                });

                $(".flyout-menu")
                    .not($flyoutContainer)
                    .hide();
            }
        }

        $(selector.menuItem)
            .parent()
            .not(".primary-logo")
            .hover(
                function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var isCurrentElement = $(this).is(lastElement);
                    var timeout = 0;

                    if ((lastElement == null && !isCurrentElement) || isCurrentElement) {
                        timeout = SitePreferences.MegaMenuSensitivityDelay;
                    }

                    var $flyoutContainer = $(this).children(selector.flyoutContainer);
                    if (timeout == 0) {
                        showMenu($flyoutContainer);
                    } else {
                        closeMenu($flyoutContainer);
                        clearTimeout(menuTimer);
                        menuTimer = setTimeout(function() {
                            showMenu($flyoutContainer);
                        }, timeout);
                    }
                },
                function(e) {
                    var showContainer = false;
                    var divX = $(this).offset().left;
                    var divY = $(this).offset().top;
                    if (
                        e.pageX > divX &&
                        e.pageY > divY &&
                        $(this)
                            .next()
                            .hasClass("primary-logo")
                    ) {
                        // bottom-right
                        showContainer = true;
                    } else if (
                        e.pageX < divX &&
                        e.pageY > divY &&
                        $(this)
                            .prev()
                            .hasClass("primary-logo")
                    ) {
                        // bottom-left
                        showContainer = true;
                    }

                    var lastIndex = null;
                    var currentIndex = $(this).index();
                    var primaryLogoIndex = $(".primary-logo").index();

                    if (lastElement != null) {
                        lastIndex = lastElement.index();
                    }

                    lastElement = $(this);
                    var flyout = $(this).children(selector.flyoutContainer);
                    clearTimeout(menuTimer);
                    if (!showContainer) {
                        hideFlyoutContainer(flyout);
                    } else if (
                        e.pageY - divY >
                        $(this)
                            .children(".flyout-menu")
                            .height()
                    ) {
                        hideFlyoutContainer(flyout);
                    }

                    var timeout = 100;
                    if (
                        (lastIndex > primaryLogoIndex && currentIndex > primaryLogoIndex) ||
                        (lastIndex < primaryLogoIndex && currentIndex < primaryLogoIndex)
                    ) {
                        timeout = 300;
                    }

                    setTimeout(function() {
                        lastElement = null;
                    }, timeout);
                }
            );

        function hideFlyoutContainer(container) {
            $(".menu-overlay").remove();
            container.hide();
        }

        if (headerContainer.length) {
            var headerOffsetTop = headerContainer.offset().top;
            var originalCss = {
                position: headerContainer.css("position"),
                top: headerContainer.css("top"),
                zIndex: headerContainer.css("zIndex")
            };
            var menuHeight = headerContainer.outerHeight();

            var lastScrollTop = 0;

            function handleStickyHeader() {
                var st = $(this).scrollTop();
                if (st > headerOffsetTop) {
                    headerContainer
                        .css({
                            position: "fixed",
                            top: 0,
                            width: "100%",
                            zIndex: 999
                        })
                        .addClass("fixed-menu");
                } else {
                    headerContainer
                        .css(originalCss)
                        .removeClass("fixed-menu")
                        .stop()
                        .show();
                    $(".menu-placeholder").remove();
                }
                lastScrollTop = st;
            }

            $(window).scroll(handleStickyHeader);
        }

        this.initSearchBrand();
        this.initFlyoutMenuMobile(selector.mobileMenu.navigationContainer);
    },
    initFlyoutMenuMobile: function(container) {
        var instance = this;
        $(container)
            .find(selector.mobileMenu.backLink)
            .click(function(e) {
                e.stopPropagation();
                $(this)
                    .parent()
                    .parent()
                    .removeClass("active");
                var $parentActive = $(this).closest(".active");
                if ($parentActive.length == 0 || $parentActive.is("#hamburger-menu")) {
                    $parentActive = $(selector.mobileMenu.navigationContainer).find(
                        selector.mobileMenu.menuContainer
                    );
                    $parentActive.css("height", "auto");
                }
                $(selector.mobileMenu.navigationContainer)
                    .find(selector.mobileMenu.menuContainer)
                    .height($parentActive.height());
            });
        $(container)
            .find(selector.mobileMenu.itemLink)
            .click(function(e) {
                var $flyoutMenu = $(this)
                    .parent()
                    .next(selector.mobileMenu.slideItem);
                if ($flyoutMenu.length > 0) {
                    $flyoutMenu.addClass("active");
                    if ($flyoutMenu.hasClass("lazyload")) {
                        var url = $flyoutMenu.data("url");
                        if (url.indexOf("level") < 0) {
                            url += "&level=1";
                        }
                        ajax.load({
                            url: url,
                            target: $flyoutMenu,
                            callback: function() {
                                $flyoutMenu.removeClass("lazyload");
                                instance.initFlyoutMenuMobile($flyoutMenu);
                                $(selector.mobileMenu.navigationContainer)
                                    .find(selector.mobileMenu.menuContainer)
                                    .height($flyoutMenu.height());
                            }
                        });
                    } else {
                        $(selector.mobileMenu.navigationContainer)
                            .find(selector.mobileMenu.menuContainer)
                            .height($flyoutMenu.height());
                    }
                    return false;
                }
            });
    },
    initSearchBrand: function() {
        $(selector.searchBrandContainer).each(function() {
            var $container = $(this);
            var $searchbrandResult = $(selector.searchbrandResult, $container),
                $btnBack = $(selector.btnBack, $container),
                $searchBrandInput = $(selector.searchBrandInput, $container);
            var buildOption = {
                maximumColums: SitePreferences.Megamenu03MaxMenuColumns,
                maximumRows: SitePreferences.Megamenu03MaxMenuRows
            };
            buildOption.itemHTML = $searchbrandResult.find('[data-template="menuitems"]').html();
            $searchbrandResult.find('[data-template="menuitems"]').html("@{items}");
            buildOption.groupHTML = $searchbrandResult.find('[data-template="groups"]').html();
            $searchbrandResult.find('[data-template="groups"]').html("@{groups}");
            buildOption.columnHTML = $searchbrandResult.find('[data-template="columns"]').html();
            $searchbrandResult.html("");
            var cid = $searchBrandInput.attr("data-cid");
            $searchBrandInput
                .keyup(function(e) {
                    var keyword = $(this).val();
                    if (keyword.length > 2) {
                        keyword = keyword.toLowerCase();
                        ajax.load({
                            url: util.appendParamsToUrl(Urls.searchBrand, {
                                cid: cid,
                                keyword: keyword
                            }),
                            target: $searchbrandResult,
                            callback: function() {}
                        });
                    } else {
                        $searchbrandResult.html("");
                    }
                })
                .focus(function() {
                    $(this)
                        .parent()
                        .parent()
                        .addClass("brand-searching");
                });
            $btnBack.click(function() {
                $(this)
                    .parent()
                    .parent()
                    .removeClass("brand-searching");
                $searchBrandInput.val("");
                $searchbrandResult.html("");
            });
        });
    },
    init: function() {
        this.initEvent();
    }
};

module.exports = menuflyout;
