var MenuFlyout = require("./MenuFlyout");

module.exports = function HamburgerMenu() {
    var $body = $("body");
    var $hamburgerMenu = $("#hamburger-menu");
    if ($hamburgerMenu.length < 1) {
        return;
    }

    var $hamburgerMenuContainer = $(".hamburger-menu__container");
    var $overlay = $hamburgerMenuContainer.find(".hamburger-menu__overlay");
    var $cartProductCount = $hamburgerMenuContainer.find(".count-label");
    var $cartButton = $hamburgerMenuContainer.find("[data-action='open-minicart']");
    var $regionPreferencesButton = $hamburgerMenuContainer.find("[data-action='region-preferences']");

    function init() {
        $.ajax({
            url: Urls.headerMenuMobile,
            success: function(res) {
                if (res) {
                    $("#hamburger-menu>.container").prepend(res);
                    MenuFlyout.init();
                }
            }
        });
    }

    function open() {
        $hamburgerMenu.addClass("active");
        $body.addClass("scroll-locked");
    }

    function close() {
        $hamburgerMenu.removeClass("active");
        $body.removeClass("scroll-locked");
    }

    function validateSelectedCurrency() {
        try {
            var currencySelected = $("#region-currency option:selected").val();
            var currencycode = $("#siteLocale").data("currencycode");
            if (currencySelected && currencycode && currencySelected !== currencycode) {
                $('#region-currency option').each(function() {
                    $(this).attr('selected', false);
                });
                $("#region-currency").val(currencycode);
                $('#region-currency option:selected').attr('selected', true);
                $("#region-preferences").find(".region-preferences__form").attr('data-current-currency', currencycode);
            }
        } catch (er) {
            console.log("currency error: " + er.message);
        }
    }

    $overlay.click(close);

    $(document).on("minicart::update-product-count", function(event, productCount) {
        if (productCount && productCount > 0) {
            $cartProductCount.text(productCount);
            $cartProductCount.addClass("visible");

            $cartButton.on("click", function(event) {
                event.preventDefault();
                close();
                $(document).trigger("minicart::open");
            });
        } else {
            $cartProductCount.removeClass("visible");
            $cartButton.off();
        }
    });

    $regionPreferencesButton.on("click", function(event) {
        event.preventDefault();
        validateSelectedCurrency();
        close();        
        $(document).trigger("region-preferences::open");
    });

    // Handle External Events
    $(document).on("hamburger-menu::open", open);
    $(document).on("hamburger-menu::close", close);

    init();
};
