"use strict";

exports.init = function($container){
	if(!$container){
			$container = $("body");
		}
		else{
			$container = $($container);
		}
		var $form = $container.find('.registration-form');
		$form.each(function(index,item){
			var $item = $(item);
			if($item.hasClass('birthday-event-ready'))
				return;
			$item.addClass('birthday-event-ready');
			var $birthdayInput = $item.find('[name$="_birthday"]');
			$item.find('.dob-selections select').change(function(){
				var year = $item.find('[name$="_dobyear"]').val(),
				month = $item.find('[name$="_dobmonth"]').val(),
				day = $item.find('[name$="_dobday"]').val();
				$birthdayInput.val([month,day,year].join("/"));
                $birthdayInput.valid();
			});

		});
};
