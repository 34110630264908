module.exports = function AccountDropdown(container) {
    var $accountDropdown = container.find(".account-dropdown");

    this.open = function() {
        if (!$accountDropdown.hasClass("expanded")) {
            $accountDropdown.addClass("expanded");
        }
    };

    this.close = function() {
        if ($accountDropdown.hasClass("expanded")) {
            $accountDropdown.removeClass("expanded");
        }
    };

    var accountDropdown = this;

    $accountDropdown
        .focusin(accountDropdown.open)
        .focusout(function() {
            var $elem = $(this);
            setTimeout(function() {
                // Timeout is required because nothing is focused during focusout event
                var hasFocus = !!($elem.find(":focus").length > 0);
                if (!hasFocus) {
                    accountDropdown.close();
                }
            });
        });

    $accountDropdown
        .mouseenter(accountDropdown.open)
        .mouseleave(accountDropdown.close);

    var $accountCloseButton = $accountDropdown.find("[data-action='close-account-dropdown']");
    $accountCloseButton.click(accountDropdown.close);
};
