var currencyCode = $("#siteLocale").data("currencycode");
var countryId = $("#siteLocale").data("countryid");

var TS2AnalyticEvent = {
    pushProductImpressionDataLayer: function(productImpressionData) {
        var currentGtmData = {
            event: "productImpression",
            funnelPageType: productImpressionData.funnelPageType,
            countryId: productImpressionData.countryId,
            ecommerce: {
                currencyCode: productImpressionData.currencyCode,
                impressions: productImpressionData.impressions
            }
        };
        if (SitePreferences.ENABLE_TRADESTATE2) {
            window.dataLayer.push(currentGtmData);
        }
    },

    pushProductClickDataLayer: function(productClickData) {
        var currentGtmData = {
            event: "productClick",
            countryId: productClickData.countryId,
            ecommerce: {
                currencyCode: productClickData.currencyCode,
                click: {
                    actionField: {
                        list: productClickData.funnelPageType
                    },
                    products: [
                        {
                            id: productClickData.productId.toString(),
                            name: productClickData.productName,
                            price: productClickData.price.toString(),
                            brand: productClickData.brand,
                            category: productClickData.category,
                            list: productClickData.funnelPageType,
                            position: productClickData.position,
                            variant: productClickData.variant
                        }
                    ]
                }
            }
        };
        if (SitePreferences.ENABLE_TRADESTATE2) {
            window.dataLayer.push(currentGtmData);
        }
    },

    pushQuickViewDataLayer: function(quickViewData) {
        var qvObject = {
            event: "quickView",
            funnelPageType: "Quick View",
            countryId: quickViewData.countryId,
            ecommerce: {
                currencyCode: quickViewData.currencyCode,
                detail: {
                    actionField: {
                        list: quickViewData.funnelPageType
                    },
                    products: [
                        {
                            id: quickViewData.productId.toString(),
                            name: quickViewData.productName,
                            price: quickViewData.price.toString(),
                            brand: quickViewData.brand,
                            category: quickViewData.category,
                            list: quickViewData.funnelPageType,
                            position: quickViewData.position,
                            variant: quickViewData.variant
                        }
                    ]
                }
            }
        };
        if (SitePreferences.ENABLE_TRADESTATE2) {
            window.dataLayer.push(qvObject);
        }
    },

    pushAddRemoveCartEvent: function(cartEventData) {
        var event = "";
        if (cartEventData.event === "add") {
            event = "addToCart";
        } else {
            event = "removeFromCart";
        }

        var gtmObject = {
            event: event,
            funnelPageType: cartEventData.funnelPageType,
            countryId: cartEventData.countryId,
            ecommerce: {
                currencyCode: cartEventData.currencyCode
            }
        };

        var actionList = cartEventData.list;
        if (typeof actionList === "undefined") {
            actionList = cartEventData.funnelPageType;
        }

        if (cartEventData.productType === "set") {
            if (cartEventData.event === "add") {
                gtmObject.ecommerce.add = {
                    actionField: {
                        list: actionList
                    },
                    products: cartEventData.products
                };
            } else {
                gtmObject.ecommerce.remove = {
                    actionField: {
                        list: actionList
                    },
                    products: cartEventData.products
                };
            }
        } else if (cartEventData.event === "add") {
            gtmObject.ecommerce.add = {
                actionField: {
                    list: actionList
                },
                products: [
                    {
                        id: cartEventData.productId.toString(),
                        name: cartEventData.productName,
                        price: cartEventData.price.toString(),
                        brand: cartEventData.brand,
                        category: cartEventData.category,
                        list: actionList,
                        quantity: cartEventData.quantity,
                        variant: cartEventData.variant
                    }
                ]
            };
        } else {
            gtmObject.ecommerce.remove = {
                actionField: {
                    list: actionList
                },
                products: [
                    {
                        id: cartEventData.productId.toString(),
                        name: cartEventData.productName,
                        price: cartEventData.price.toString(),
                        brand: cartEventData.brand,
                        category: cartEventData.category,
                        list: actionList,
                        quantity: cartEventData.quantity,
                        variant: cartEventData.variant
                    }
                ]
            };
        }

        if (SitePreferences.ENABLE_TRADESTATE2) {
            window.dataLayer.push(gtmObject);
        }
    },

    addFunnelPageType: function() {
        var contentGroupObj = {
            funnelPageType: "",
            countryId: countryId,
            ecommerce: {
                currencyCode: currencyCode
            }
        };

        var gtmPageName = $(".gtmList").data("gtmlist");
        if (typeof gtmPageName !== "undefined" && SitePreferences.ENABLE_TRADESTATE2) {
            contentGroupObj.funnelPageType = gtmPageName;
            window.dataLayer.push(contentGroupObj);
        }
    }
};

var exclusionList = ["Product Listing Page", "Product Search Page", "Product Details Page", "Cart Page", "Checkout Shipping Page", "Checkout Billing Page", "Confirmation Page"];
var gtmPage = $(".gtmList").data("gtmlist");
if (exclusionList.indexOf(gtmPage) < 0) {
    TS2AnalyticEvent.addFunnelPageType();
}

// productImpression in carousel
window.addEventListener("load", function () {
    // For product impression on load - Carousel products
    if ($("div.ss-product-cycler").length > 0) {
        $(".ss-product-cycler").each(function () { // product-slot loop
            var pos = 0;
            var slotTitle = $(this).find("h3").text();
            var productObj = "";
            if (slotTitle !== "") {
                var impressions = [];
                $(this).find(".slide-item").each(function () { // products loop inside product-slot
                    pos += 1;
                    var price = $(this).find(".ss-product-tile__price").text().replace("$", "")
                        .split("$")[0];
                    productObj = {
                        id: $(this).data("pid"),
                        name: $(this).find(".product-name").text(),
                        price: price,
                        category: $(this).data("category"),
                        list: slotTitle,
                        brand: $(this).find(".brand").text(),
                        position: pos,
                        variant: ""
                    };
                    impressions.push(productObj);
                });

                var productImpressionData = {
                    funnelPageType: $(".gtmList").data("gtmlist"),
                    countryId: countryId,
                    currencyCode: currencyCode,
                    impressions: impressions
                };

                if (impressions.length > 0) {
                    TS2AnalyticEvent.pushProductImpressionDataLayer(productImpressionData);
                }
            }
        });
    }
    // For product impression on load - PLP and SRP page
    if ($("#search-result-items").length > 0) {
        var pList = 0;
        var pdtObj = "";
        var gtmPageName = $(".gtmList").data("gtmlist");
        var impressions = [];
        $("#search-result-items li.grid-tile").each(function() {
            if ($(this).find(".product-tile").data("itemid")) {
                pList += 1;

                var price = $(this).find(".product-sales-price").text().trim()
                    .replace(/[^0-9\.]/g, "");
                pdtObj = {
                    id: $(this).find(".product-tile").data("itemid"),
                    name: $(this).find(".swiper-product-tile-name").text(),
                    price: price,
                    category: $(this).find(".product-tile").data("category"),
                    list: gtmPageName,
                    brand: $(this).find(".brand-name").text(),
                    position: pList,
                    variant: $(this).data("colors-to-show")
                };
                impressions.push(pdtObj);
            }
        });

        var productImpressionData = {
            funnelPageType: gtmPageName,
            countryId: countryId,
            currencyCode: currencyCode,
            impressions: impressions
        };

        if (impressions.length > 0) {
            TS2AnalyticEvent.pushProductImpressionDataLayer(productImpressionData);
        }
    }

    // For product impression on load - wishlist page
    if ($(".wishlist-item-list").length > 0) {
        var pwList = 0;
        var wProductObj = "";
        var list = $(".gtmList").data("gtmlist");
        console.log("List: " + list);

        var wishListObjArray = [];
        $(".wishlist-item-list tr").each(function() {
            var variant = "";
            if ($(this).find(".product-list-item").find("div.attribute").length > 0) {
                $(this).find(".product-list-item").find("div.attribute").each(function () {
                    if ($(this).data("attribute") === "swatchColour") {
                        variant = $(this).find(".value").text().trim();
                    }
                });
            }
            pwList += 1;
            var price = $(this).find(".price").find(".price-sales").text()
                .trim()
                .replace(/[^0-9\.]/g, "");
            wProductObj = {
                id: $(this).find(".product-list-item").data("itemid"),
                name: $(this).find(".product-list-item").find(".name").text()
                    .replace(/\n/g, ""),
                price: price,
                list: list,
                brand: $(this).find(".product-list-item").data("brand"),
                category: $(this).find(".product-list-item").data("category"),
                position: pwList - 1,
                quantity: $(this).find(".qty-wishlist").val(),
                variant: variant
            };
            if (wProductObj.id) {
                wishListObjArray.push(wProductObj);
            }
        });

        var wishlistProductImpressionData = {
            funnelPageType: list,
            countryId: countryId,
            currencyCode: currencyCode,
            impressions: wishListObjArray
        };

        if (wishListObjArray.length > 0) {
            TS2AnalyticEvent.pushProductImpressionDataLayer(wishlistProductImpressionData);
        }
    }
});

// product click event for carousels
$("body").on("click", ".ss-product-tile", function () {
    var list = $(".gtmList").data("gtmlist");
    var slotTitle = $(this).closest(".ss-product-cycler").find("h3.title").text();
    console.log(slotTitle);
    // --- getting list
    if (slotTitle.length > 0) {
        list = slotTitle;
    }
    sessionStorage.setItem("gtmListValue", list);

    var productClickData = {
        productId: $(this).parent().data("pid"),
        productName: $(this).find(".product-name").text(),
        price: $(this).find(".ss-product-tile__price").text().replace("$", "")
            .split("$")[0],
        category: $(this).parent().data("category"),
        brand: $(this).find(".brand").text(),
        position: $(this).parent().data("slide-index") + 1,
        funnelPageType: list,
        countryId: countryId,
        currencyCode: currencyCode
    };

    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
});

// product click event for PLP and SRP
$("body").on("click", ".product-tile", function (e) {
    var list = $(".gtmList").data("gtmlist");
    sessionStorage.setItem("gtmListValue", list);
    var target = $(e.target);
    var pos = $(this).parent().data("tsposition");
    pos++;
    var productClickData = {
        productId: $(this).data("itemid"),
        productName: $(this).find(".swiper-product-tile-name").text(),
        price: $(this).find(".product-sales-price").text().trim()
            .replace(/[^0-9\.]/g, ""),
        category: $(this).data("category"),
        brand: $(this).find(".brand-name").text(),
        position: pos++,
        variant: $(this).parent("li.grid-tile").data("colors-to-show"),
        funnelPageType: list,
        countryId: countryId,
        currencyCode: currencyCode
    };
    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
    if (target.is(".quickview")) {
        TS2AnalyticEvent.pushQuickViewDataLayer(productClickData);
    }
});

// Edit details in cart page
$("body").on("click", ".item-edit-details a", function(e) {
    var elem = $(this).closest("tr");
    var variant = "";
    if (elem.find("div.attribute").length > 0) {
        elem.find("div.attribute").each(function () {
            if ($(this).data("attribute") === "swatchColour") {
                variant = $(this).find(".value").text().trim();
            }
        });
    }

    var productClickData = {
        productId: elem.find(".defaultQty").data("itemid"),
        productName: elem.find(".name a").text(),
        price: elem.find(".item-price span.price-sales").text().trim().replace(/[^0-9\.]/g, ""),
        category: elem.find(".defaultQty").data("category"),
        brand: elem.find(".brand-name").text(),
        variant: variant,
        funnelPageType: $(".gtmList").data("gtmlist"),
        countryId: countryId,
        currencyCode: currencyCode
    };
    sessionStorage.setItem("gtmProductId", elem.find("div.attribute.sku").data("pid"));
    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
    TS2AnalyticEvent.pushQuickViewDataLayer(productClickData);
});

// Edit gift certificate details in cart page
$("body").on("click", ".item-giftcert-edit a", function(e) {
    var elem = $(this).closest("tr");
    var productClickData = {
        productId: elem.data("lineitem"),
        productName: elem.find(".item-details .name").text().trim(),
        price: elem.find(".item-total").text().trim().replace(/[^0-9\.]/g, ""),
        category: "",
        brand: "",
        funnelPageType: $(".gtmList").data("gtmlist"),
        countryId: countryId,
        currencyCode: currencyCode
    };
    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
});

// Product click event for minicart list
$("body").on("click", ".mini-cart-products .mini-cart-name a", function() {
    var list = "Mini Cart";
    sessionStorage.setItem("gtmListValue", list);

    var productClickData = {
        productId: $(this).data("itemid"),
        productName: $(this).text(),
        price: $(this).parent().next().next()
            .find(".mini-cart-price")
            .text()
            .trim()
            .replace(/[^0-9\.]/g, ""),
        category: $(this).data("category"),
        brand: $(this).parent().parent().find(".brand-name")
            .text(),
        position: $(this).data("position") + 1,
        funnelPageType: list,
        countryId: countryId,
        currencyCode: currencyCode
    };

    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
});

// Product click event from cart page
$("body").on("click", ".cart-row .product-list-item .name a", function() {
    var list = "Cart Page";
    sessionStorage.setItem("gtmListValue", list);
    var elem = $(this).closest("tr");
    var variant = "";
    if (elem.find("div.attribute").length > 0) {
        elem.find("div.attribute").each(function () {
            if ($(this).data("attribute") === "swatchColour") {
                variant = $(this).find(".value").text().trim();
            }
        });
    }

    var productClickData = {
        productId: elem.find("div.attribute.sku").data("pid"),
        productName: elem.find(".name a").text(),
        price: elem.find(".item-price span.price-sales").text().trim().replace(/[^0-9\.]/g, ""),
        category: elem.find(".defaultQty").data("category"),
        brand: elem.find(".brand-name").text(),
        position: elem.index() + 1,
        variant: variant,
        funnelPageType: list,
        countryId: countryId,
        currencyCode: currencyCode
    };

    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
});

// Product click event from wishlist page
$("body").on("click", ".wishlist-item-list td.item-details a", function() {
    var list = "Wishlist Page";
    sessionStorage.setItem("gtmListValue", list);
    var elem = $(this).closest("tr");
    var variant = "";
    if (elem.find(".product-list-item").find("div.attribute").length > 0) {
        elem.find(".product-list-item").find("div.attribute").each(function () {
            if ($(this).data("attribute") === "swatchColour") {
                variant = $(this).find(".value").text().trim();
            }
        });
    }
    var productClickData = {
        productId: elem.find(".product-list-item").data("itemid"),
        productName: elem.find(".name a").text().replace(/\n/g, ""),
        price: elem.find(".price .price-sales").text().trim().replace(/[^0-9\.]/g, ""),
        brand: elem.find(".product-list-item").data("brand"),
        category: elem.find(".product-list-item").data("category"),
        position: elem.index(),
        variant: variant,
        funnelPageType: list,
        countryId: countryId,
        currencyCode: currencyCode
    };

    TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
});

// Product click event from Search Recommendation
$("body").on("click", ".search-results__dynamic-suggestions .search-result .search-result__label", function() {
    var list = "Search Recommendation";
    sessionStorage.setItem("gtmListValue", list);
    var searchVal = JSON.parse($("#gtm-search-suggestions").val());
    var length = searchVal.ecommerce.impressions.length;
    var name = [];
    for (var i = 0; i < length; i++) {
        name.push(searchVal.ecommerce.impressions[i].name);
    }
    var productName = $(this).closest("li").find(".search-result__label").text();
    for (var j = 0; j < name.length; j++) {
        if (name[j] === productName) {
            var productClickData = {
                productId: searchVal.ecommerce.impressions[j].id,
                productName: name[j],
                price: searchVal.ecommerce.impressions[j].price,
                category: searchVal.ecommerce.impressions[j].category,
                position: searchVal.ecommerce.impressions[j].position,
                funnelPageType: list,
                countryId: countryId,
                currencyCode: currencyCode
            };

            TS2AnalyticEvent.pushProductClickDataLayer(productClickData);
        }
    }
});

// removeCart event
$("body").on("click", ".button-text", function() {
    if ($(this).val() === "Remove") {
        var elem = $(this).closest("tr");

        var variant = "";
        if (elem.find("div.attribute").length > 0) {
            elem.find("div.attribute").each(function () {
                if ($(this).data("attribute") === "swatchColour") {
                    variant = $(this).find(".value").text().trim();
                }
            });
        }

        var cartEventData = {
            category: elem.find(".defaultQty").data("category"),
            funnelPageType: $(".gtmList").data("gtmlist"),
            list: $(".gtmList").data("gtmlist"),
            countryId: countryId,
            currencyCode: currencyCode,
            event: "remove"
        };

        if (elem.hasClass("rowgiftcert")) {
            // Remove gift cert
            cartEventData.productId = elem.data("lineitem");
            cartEventData.productName = elem.find(".item-details .name").text().trim();
            cartEventData.price = elem.find(".item-total").text().trim().replace(/[^0-9\.]/g, "");
            cartEventData.brand = "";
            cartEventData.quantity = elem.find(".item-quantity").text();
        } else {
            cartEventData.productId = elem.find(".defaultQty").data("itemid");
            cartEventData.productName = elem.find(".name a").text();
            cartEventData.price = elem.find(".item-price span.price-sales").text().trim().replace(/[^0-9\.]/g, "");
            cartEventData.brand = elem.find(".brand-name").text();
            cartEventData.quantity = elem.find(".item-quantity").find("input").val();
            cartEventData.variant = variant;
        }
        TS2AnalyticEvent.pushAddRemoveCartEvent(cartEventData);
    }
});

$("body").on("click", ".update-item", function() {
    var elem = $(this).closest("tr");
    var defaultQty = elem.find(".defaultQty").val();
    var currentQty = elem.find(".input-text").val();

    var variant = "";
    if (elem.find("div.attribute").length > 0) {
        elem.find("div.attribute").each(function () {
            if ($(this).data("attribute") === "swatchColour") {
                variant = $(this).find(".value").text().trim();
            }
        });
    }

    var cartEventData = {
        productId: elem.find(".defaultQty").data("itemid"),
        productName: elem.find(".name a").text(),
        price: elem.find(".item-price span.price-sales").text().trim().replace(/[^0-9\.]/g, ""),
        category: elem.find(".defaultQty").data("category"),
        brand: elem.find(".brand-name").text(),
        variant: variant,
        funnelPageType: $(".gtmList").data("gtmlist"),
        list: $(".gtmList").data("gtmlist"),
        countryId: countryId,
        currencyCode: currencyCode
    };
    var qty = "";
    if (defaultQty > currentQty) {
        qty = defaultQty - currentQty;
        cartEventData.quantity = qty;
        cartEventData.event = "remove";
        TS2AnalyticEvent.pushAddRemoveCartEvent(cartEventData);
    }
    if (defaultQty < currentQty) {
        qty = currentQty - defaultQty;
        cartEventData.quantity = qty;
        cartEventData.event = "add";
        TS2AnalyticEvent.pushAddRemoveCartEvent(cartEventData);
    }
});

function updateFunnelPageType(jsonData) {
    if (typeof jsonData !== "undefined") {
        var pageType = $(".gtmList").data("gtmlist");
        if (typeof pageType !== "undefined" && pageType !== "") {
            jsonData.funnelPageType = pageType;
        }
    }
    return jsonData;
}

function updateCurrencyCode(jsonData) {
    if (typeof jsonData !== "undefined") {
        if (typeof currencyCode !== "undefined" && currencyCode !== "") {
            jsonData.ecommerce.currencyCode = currencyCode;
        }
    }
    return jsonData;
}

function updateListValue(jsonData) {
    console.log("updateListValue");
    if (typeof jsonData !== "undefined" && jsonData != null) {
        var listValue = sessionStorage.getItem("gtmListValue");
        if (jsonData.event === "productView") {
            if (listValue) {
                jsonData.ecommerce.detail.actionField.list = listValue;
            } else {
                jsonData.ecommerce.detail.actionField.list = "Direct";
                sessionStorage.setItem("gtmListValue", "Direct");
            }
        }
    }
    return jsonData;
}

if (window.gtmData) {
    var tmpGtmData = window.gtmData;
    if (SitePreferences.ENABLE_TRADESTATE2) {
        if (Array.isArray(tmpGtmData)) {
            for (var i = 0; i < tmpGtmData.length; i += 1) {
                var tempGtmData = JSON.parse(tmpGtmData[i]);
                tempGtmData = updateFunnelPageType(tempGtmData);
                tempGtmData = updateCurrencyCode(tempGtmData);
                tempGtmData = updateListValue(tempGtmData);
                window.dataLayer.push(tempGtmData);
            }
        } else {
            tmpGtmData = updateFunnelPageType(tmpGtmData);
            tmpGtmData = updateCurrencyCode(tmpGtmData);
            tmpGtmData = updateListValue(tmpGtmData);
            window.dataLayer.push(tmpGtmData);
        }
    }
}

module.exports = TS2AnalyticEvent;
