module.exports = function SearchForm(container) {
    var $searchContainer = container.find(".search__container");
    var $shadowOverlay = container.find(".search__overlay-shadow");
    var $searchForm = $searchContainer.find(".search__form");
    var $submitButton = container.find(".search__submit-button");
    var $searchInput = container.find(".search__input");
    var $body = $("body");
    var $searchResults = container.find(".search-results");
    var $initialResults = container.find(".search-results__initial-suggestions");
    var $dynamicResults = container.find(".search-results__dynamic-suggestions");
    var $showAllContainer = container.find(".search-results__show-all-container");

    var getSuggestionsURL = _.get(window, "data.components.headerNavigation.getSuggestionsURL");
    var getSuggestionsRequest;

    function open() {
        $searchContainer.addClass("expanded");
        $body.addClass("scroll-locked");
    }

    function close() {
        $searchContainer.removeClass("expanded");
        $body.removeClass("scroll-locked");
    }

    function showDynamicResults() {
        if ($dynamicResults.find(".search-result").size() < 1) {
            $searchResults.addClass("hidden");
        } else {
            $searchResults.removeClass("hidden");
            $dynamicResults.removeClass("hidden");
            $initialResults.addClass("hidden");
            $showAllContainer.removeClass("hidden");
        }
    }

    function showInitialResults() {
        $searchResults.removeClass("hidden");
        $dynamicResults.addClass("hidden");
        $initialResults.removeClass("hidden");
        $showAllContainer.addClass("hidden");
    }

    function getSuggestions(searchPhrase) {
        if (!getSuggestionsURL) {
            console.error(
                "headerNavigation.getSuggestionsURL is missing, check HeaderNavigation component data"
            );
            return;
        }

        if (getSuggestionsRequest) {
            getSuggestionsRequest.abort();
            getSuggestionsRequest = null;
        }

        if (searchPhrase.length < 3) {
            showInitialResults();
            return;
        }

        getSuggestionsRequest = $.ajax({
            method: "GET",
            url: getSuggestionsURL,
            data: { q: searchPhrase }
        });

        getSuggestionsRequest.done(function(data) {
            $dynamicResults.html(data);
            showDynamicResults();
            getSuggestionsRequest = null;
            if (SitePreferences.ENABLE_TRADESTATE2) {
            	var searchVal = $('#gtm-search-suggestions').val();
            	window.dataLayer.push(JSON.parse(searchVal));
            }
        });
    }

    // Enable or Disable form submit button based on search input value
    function toggleSubmitButton() {
        if ($searchInput.val().length > 0) {
            $submitButton.addClass("active").prop("disabled", false);
        } else {
            $submitButton.removeClass("active").prop("disabled", true);
        }
    }

    function selectSuggestion(searchPhrase, url) {
        $searchInput.val(searchPhrase);
        toggleSubmitButton();

        if (url) {
            $submitButton.focus();
            close();
            window.location = url;
        } else {
            $searchForm.submit();
        }
    }

    $searchContainer.focusin(open).focusout(function() {
        var $elem = $(this);
        setTimeout(function() {
            // Timeout is required because nothing is focused during focusout event
            var hasFocus = !!($elem.find(":focus").length > 0);
            if (!hasFocus) {
                close();
            }
        });
    });

    $submitButton.prop("disabled", true);

    $shadowOverlay.click(close);

    $searchResults.on("click", ".search-result", function() {
        var url = $(this).data("url");
        selectSuggestion(this.value, url);
    });

    $searchInput.on("input", function() {
        toggleSubmitButton();
    });

    $searchInput.on(
        "input",
        _.debounce(function() {
            getSuggestions($searchInput.val());
        }, 400)
    );

    $searchForm.submit(function() {
        $submitButton.focus();
        close();
    });

    /* $showAllButton.click(function() {
        searchContainer.getSuggestions();
    }); */

    // TODO: Check if search form was focused before script was initialized
};
