'use strict';

var util = require('../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/util'),
	_ = require('lodash'),
	mobileLayoutWidth = 768,
    tabletLayoutWidth = 1200;

var $cache = {
	wrapper: $('#wrapper')
};

var originIsMobile = util.isMobile;

util.replaceQueryString = function (url, newUrl) {
    newUrl = newUrl || window.location.href;

    if (!_.isString(url) || !_.isString(newUrl)) { return; }
    var a = document.createElement('a');
    a.href = url;

    var newA = document.createElement('a');
    newA.href = newUrl;

    a.search = newA.search;

    return a.href;
}

util.isTablet = function () {
	return ($cache.wrapper.width() > mobileLayoutWidth) && ($cache.wrapper.width() <= tabletLayoutWidth);
}

util.isMobile = function () {
    return originIsMobile() || $cache.wrapper.width() <= mobileLayoutWidth;
}

/**
 * @function getCookie
 *
 * @param {string} key - The cookie name
 * @returns {string} value - the value of the cookie if found, null otherwise
 **/
util.getCookie = function (key) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var tokens = cookies[i].split('=');
        var cookieKey = tokens[0].trim();
        if (cookieKey === key) {
            return tokens[1];
        }
    }
    return '';
}

module.exports = util;
