module.exports = function BonusProductModal(selector) {
    var $component = $(selector);
    if ($component.length < 1) {
        return;
    }

    var refs = $component.refs();
    var $body = $("body");

    var data = refs.data.innerHTML;
    if (data) {
        data = JSON.parse(data);
    }

    var selectedBonusProducts = [];

    function addBonusProductsToCart(productIDs, bonusDiscountLineItemUUID) {
        $component.attr("data-loading", "");

        $.ajax({
            type: "POST",
            data: {
                bonusDiscountLineItemUUID: bonusDiscountLineItemUUID,
                productIDs: productIDs
            },
            url: data.BonusProduct.Add,
            success: function() {
                close();
                $(document).trigger("bonus-product-modal::closed");
            },
            error: function() {
                console.error("Something went wrong while trying to select bonus product");
                $component.removeAttr("data-loading");
            }
        });
    }

    function attachEventHandlers() {
        refs = $component.refs();

        $("[name='colorSelect']").on("change", function() {
            var $colorSelect = $(this);
            var $product = $(this).closest("[name='bonusProduct']");

            var selectedColor = $colorSelect.find(":selected");

            // Update product image
            var imageURL = selectedColor.data("image-url");
            if (imageURL) {
                var $productImage = $product.find("[name='productImage']");
                $productImage.attr("src", imageURL);
            }

            // Handle color only product
            var variantID = selectedColor.data("variant-id");
            if (variantID) {
                $product.attr("data-variant-id", variantID);
                return;
            }

            var sizes = selectedColor.data("sizes");
            if (!sizes || sizes.length < 1) {
                console.error("Could not find data-sizes attribute on", selectedColor);
                return;
            }

            var $sizeSelect = $product.find("[name='sizeSelect']");

            $sizeSelect.find("option").remove();

            sizes.map(function(size) {
                $sizeSelect.append(
                    "<option value='" + size.ID + "'>" + size.displayName + "</option>"
                );
            });

            var defaultSize = $sizeSelect.find("option").first();

            $sizeSelect.val(defaultSize.val());
            $product.attr("data-variant-id", defaultSize.val());
        });

        $("[name='sizeSelect']").on("change", function() {
            var $sizeSelect = $(this);
            var $product = $(this).closest("[name='bonusProduct']");

            var $selectedSize = $sizeSelect.find(":selected");

            var variantID = $selectedSize.val();

            if (!variantID) {
                console.error("Failed to find variantID for selected size");
                return;
            }

            $product.attr("data-variant-id", variantID);
        });

        $("[name='selectProduct']").click(function() {
            var $product = $(this).closest("[name='bonusProduct']");
            var $bonusDiscountItem = $(this).closest("[name='bonusDiscountLineItem']");

            var productID = $product.attr("data-variant-id");

            if (!productID) {
                console.error("Could not find product ID for bonus product item", this);
                return;
            }

            var variantInfo = $product.find(".product-variant-info").html();
            var $variantSelectors = $product.find(".variant-selectors");
            var $colorSelector = $variantSelectors.find(".color-selector");
            var $sizeSelector = $variantSelectors.find(".size-selector");

            if ($colorSelector.length > 0 && $sizeSelector.length > 0) {
                variantInfo = data.Resources.sizeColourDescription.replace("{0}", $sizeSelector.find("[name='sizeSelect'] option:selected").text())
                                                                  .replace("{1}", $colorSelector.find("[name='colorSelect'] option:selected").text());
            } else if ($colorSelector.length > 0) {
                variantInfo = data.Resources.colourDescription.replace("{0}", $colorSelector.find("[name='colorSelect'] option:selected").text());
            }

            addBonusProductSelection({
                productID: productID,
                productImage: $product.find(".product-image img").attr('src'),
                productName: $product.find(".product-name").html(),
                productVariantInfo: variantInfo,
                productMasterID: $product.attr("data-master-id")
            });

            // If number of bonus product selection is reached to bonus selection limit, 
            // then disable unselected bonus products
            if (selectedBonusProducts.length >= $bonusDiscountItem.data("max-bonus-items")) {
                $(this).closest(".available-bonus-product__list").find(".bonus-product").each(function() {
                    let productID = $(this).attr("data-variant-id");
                    let productMasterID = $(this).attr("data-master-id");
        
                    if (!isSelectedBonusProduct(productID, productMasterID)) {
                        $(this).find("[name='selectProduct']").attr("disabled", true);
                    }
                });
            }

            $(this).html("Selected").attr('disabled', true).addClass("selected");
            $(refs.next).removeClass('hidden');
        });

        $("[name='next']").click(function() {
            $(refs.next).addClass('hidden');
            renderCurrentSelection();
            renderOtherBonusProducts();
            scrollToTop();
        });

        $("[name='updateSelection']").click(function() {
            initializeSelection();
        });

        $("[name='addToCart']").click(function() {
            var productIDs = [];

            for (let i = 0; i < selectedBonusProducts.length; i++) {
                productIDs.push(selectedBonusProducts[i].productID);
            }

            if (productIDs.length === 0) {
                console.error("Could not find any product IDs for bonus product items");
                return;
            }

            var $bonusDiscountItem = $(this).closest("[name='bonusDiscountLineItem']");
            var bonusDiscountUUID = $bonusDiscountItem.data("uuid");

            if (!bonusDiscountUUID) {
                console.error("Could not find bonus discount line item UUID");
                return;
            }

            addBonusProductsToCart(productIDs.join("|"), bonusDiscountUUID);
        });
    }

    function getModalContent(UUID) {
        if (!data.BonusProduct.GetModalContent) {
            console.error("Cannot find BonusProduct.GetModalContent data");
            return;
        }

        $component.attr("data-loading", "");

        $.ajax({
            type: "GET",
            data: {
                UUID: UUID
            },
            url: data.BonusProduct.GetModalContent,
            success: function(data) {
                refs.bodyContainer.innerHTML = data;

                $component.removeAttr("data-loading");

                scrollToTop();
                resetBonusProductSelection();
                attachEventHandlers();
            },
            error: function() {
                console.error(
                    "Something went wrong while trying to get bonus product modal partial"
                );
            }
        });
    }

    function isSelectedBonusProduct(productID, productMasterID) {
        for (let i = 0; i < selectedBonusProducts.length; i++) {
            if (productID === selectedBonusProducts[i].productID || productMasterID === selectedBonusProducts[i].productMasterID) {
                return true;
            }
        }
        return false;
    }

    function addBonusProductSelection(product) {
        if (isSelectedBonusProduct(product.productID, product.productMasterID)) {
            return;
        }
        selectedBonusProducts.push(product);
    }

    function resetBonusProductSelection() {
        selectedBonusProducts = [];
    }

    function initializeSelection() {
        resetBonusProductSelection();

        let $selectedBonusProductContainer = $(".selected-bonus-product-container");
        $selectedBonusProductContainer.addClass("hidden");
        $selectedBonusProductContainer.find(".selected-bonus-product__list").empty();

        let $selectedBonusProductControl = $selectedBonusProductContainer.find(".selected-bonus-product__control");
        let $addToCart = $selectedBonusProductControl.find("[name='addToCart']");
        let $updateSelection = $selectedBonusProductControl.find("[name='updateSelection']");
        $addToCart.off("click");
        $updateSelection.off("click");

        let $availableBonusProductContainer = $(".available-bonus-product-container");
        $availableBonusProductContainer.removeClass("hidden");
        $availableBonusProductContainer.find(".other-bonus-products-title").addClass("hidden");
        $availableBonusProductContainer.find(".max-bonus-items").removeClass("hidden");

        $availableBonusProductContainer.find(".available-bonus-product__list").find(".bonus-product").each(function() {
            $(this).removeClass("hidden");

            let $bonusProductControl = $(this).find(".bonus-product__control");
            $bonusProductControl.removeClass("hidden");
            $bonusProductControl.find("[name='selectProduct']").html("Select").attr("disabled", false).removeClass("selected");
        });

        attachEventHandlers();
        scrollToTop();
    }

    function renderCurrentSelection() {
        let $selectedBonusProductContainer = $(".selected-bonus-product-container");
        $selectedBonusProductContainer.removeClass("hidden");

        let $selectedBonusProductList = $selectedBonusProductContainer.find(".selected-bonus-product__list");
        $selectedBonusProductList.empty();

        for (let i = 0; i < selectedBonusProducts.length; i++) {
            let $productImage = $("<div/>", {
                "class": "product-image"
            }).append($("<img/>", {
                "name": "productImage",
                "alt": selectedBonusProducts[i].productName,
                "src": selectedBonusProducts[i].productImage
            }));
            let $productImageContainer = $("<div/>", {
                "class": "product-image__container"
            });
            $productImageContainer.append($productImage);

            let $productName = $("<span/>", {
                "class": "product-name",
                "html": selectedBonusProducts[i].productName
            });
            let $productVariantInfo = $("<span/>", {
                "class": "product-variant-info",
                "html": selectedBonusProducts[i].productVariantInfo
            });
            let $productDescription = $("<div/>", {
                "class": "product-description"
            });
            $productDescription.append($productName).append($productVariantInfo);

            let $bonusProductInfo = $("<div/>", {
                "class": "bonus-product__info"
            });
            $bonusProductInfo.append($productImageContainer);
            $bonusProductInfo.append($productDescription);

            let $bonusProduct = $("<div/>", {
                "class": "bonus-product",
                "name": "bonusProduct"
            });
            $bonusProduct.attr("data-variant-id", selectedBonusProducts[i].productID).append($bonusProductInfo);

            $selectedBonusProductList.append($bonusProduct);
        }

        $("[name='addToCart']").closest(".control-button").removeClass("hidden");
    }

    function renderOtherBonusProducts() {
        let $availableBonusProductContainer = $(".available-bonus-product-container");
        $availableBonusProductContainer.find(".other-bonus-products-title").removeClass("hidden");
        $availableBonusProductContainer.find(".max-bonus-items").addClass("hidden");

        let $bonusProductList = $availableBonusProductContainer.find(".available-bonus-product__list .bonus-product");
        if (selectedBonusProducts.length === $bonusProductList.length) {
            $availableBonusProductContainer.addClass("hidden");
        }

        $bonusProductList.each(function() {
            let $bonusProduct = $(this);

            let $bonusProductControl = $bonusProduct.find(".bonus-product__control");
            $bonusProductControl.addClass("hidden");
            $bonusProductControl.find("[name='selectProduct']").attr("disabled", true);
            let productID = $bonusProduct.attr("data-variant-id");
            let productMasterID = $bonusProduct.attr("data-master-id");

            if (isSelectedBonusProduct(productID, productMasterID)) {
                $bonusProduct.addClass("hidden");
            }
        });
    }

    function scrollToTop() {
        refs.bodyContainer.scrollTop = 0;
    }

    function open() {
        resetBonusProductSelection();
        $body.addClass("scroll-locked");
        $component.attr("data-active", "");
    }

    function close() {
        resetBonusProductSelection();
        $component.removeAttr("data-active");
        $body.removeClass("scroll-locked");
    }

    $(document).on("bonus-product-modal::open", function(event, UUID) {
        open();
        getModalContent(UUID); // TODO: Check if being preloaded, do not update then
    });

    $(refs.close).click(close);
    $(refs.overlay).click(close);

    var storedUUID = sessionStorage.getItem("bonusModalUUID");

    if (storedUUID) {
        sessionStorage.removeItem("bonusModalUUID");
        open();
        getModalContent(storedUUID);
    }
};
