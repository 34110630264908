var AccountDropdown = require("./AccountDropdown");
var SearchForm = require("./SearchForm");
var Navigation = require("./Navigation");

module.exports = function HeaderNavigation() {
    var $headerNavigation = $("#header-navigation");
    if ($headerNavigation.length < 1) {
        return;
    }

    var $hamburgerButton = $headerNavigation.find("[data-action='toggle-menu']");
    $hamburgerButton.click(function() {
        $(document).trigger("hamburger-menu::open");
    });

    var accountDropdown = new AccountDropdown($headerNavigation);

    var $accountButton = $headerNavigation.find("[data-action='open-account-dropdown']");
    $accountButton.click(accountDropdown.open);
    $accountButton.mouseenter(accountDropdown.open).mouseleave(accountDropdown.close);

    var searchForm = new SearchForm($headerNavigation);
    var navigation = new Navigation($headerNavigation);

    var $cartButton = $headerNavigation.find("[data-action='open-minicart']");
    var $cartProductCount = $headerNavigation.find(".minicart-quantity");
    $(document).on("minicart::update-product-count", function(event, productCount) {
        if (productCount && productCount > 0) {
            $cartProductCount.text(productCount);
            $cartProductCount.addClass("visible");

            $cartButton.on("click", function(event) {
                event.preventDefault();
                $(document).trigger("minicart::open");
            });
        } else {
            $cartProductCount.removeClass("visible");
            $cartButton.off();
        }
    });

    var $regionPreferencesButton = $headerNavigation.find("[data-action='region-preferences']");
    $regionPreferencesButton.on("click", function(event) {
        event.preventDefault();
        validateCurrency();
        $(document).trigger("region-preferences::open");
    });

    function validateCurrency() {
        try {
            var currencySelected = $("#region-currency option:selected").val();
            var currencycode = $("#siteLocale").data("currencycode");
            if (currencySelected && currencycode && currencySelected !== currencycode) {
                $('#region-currency option').each(function() {
                    $(this).attr('selected', false);
                });
                $("#region-currency").val(currencycode);
                $('#region-currency option:selected').attr('selected', true);
                $("#region-preferences").find(".region-preferences__form").attr('data-current-currency', currencycode);

            }
        } catch (er) {
            console.log("currency error: " + er.message);
        }
    }

    // Sticky Logic
    // Warning: Be careful as this is optimised for performance and any changes might be highly detrimental to user experience
    var $headboard = $("#headboard");
    var $secondaryHeader = $headerNavigation.find(".secondary-header__container");

    function collapse() {
        if (window.pageYOffset > 30) {
            if (!($secondaryHeader.find(":focus").length > 0)) {
                // Dont collapse if focused
                $headboard.addClass("collapsed");
            }
        } else {
            $headboard.removeClass("collapsed");
        }
    }

    window.onscroll = _.debounce(collapse, 10); // Adjust debounce rate if performance is not ideal
    collapse();

    $secondaryHeader
        .focusin(function() {
            // Expand if collapsed
            if ($headboard.hasClass("collapsed")) {
                $headboard.removeClass("collapsed");
            }
        })
        .focusout(function() {
            setTimeout(function() {
                collapse();
            });
        });
};
