var ajax = require("./ajax");

var selector = window.components.Selectors.components.newsletter;
var util = window.components.Util;
var validator = window.components.Validator;
var $form = $(selector.formcontainer);

var newsletter = {
    submit: function(token, action) {
        validator.initForm($form);
        var emailInput = $form.find(selector.email);
        var mobileInput = $form.find(selector.mobile);
        var genderInput = $form.find(selector.gender);

        if (emailInput.valid() && genderInput.valid()) {
            var params = {
                email: emailInput.val(),
                gender: genderInput.val()
            };

            if (token) {
                params.gReCaptchaResponseToken = token;
                params.gReCaptchaResponseAction = action;
            }

            // Mobile is an optional field
            if (mobileInput.length) {
                if (mobileInput.valid()) {
                    params.mobile = mobileInput.val();
                } else {
                    return false;
                }
            }

            if (!params.email || !params.gender) {
                return false;
            }

            var url = Urls.newslettersubscription;
            url.replace(/^https+:/, "");

            ajax.getJson({
                url: url,
                method: "get",
                data: params,
                callback: function (data) {
                    if (data.status == "ERROR") {
                        var $message = $form.find(".newsletter-message");

                        if (!$message.length) {
                            $message = $("<div class=\"newsletter-message\"></div>");
                            $form.append($message);
                        }

                        $message.html(data.message);
                        $(".footer-newsletter").html("").append($message);
                    } else {
                        $(".footer-newsletter").html("").append($("#subscription-success"));
                        $("#subscription-success").removeClass("hidden");
                    }
                }
            });
        }

        return false;
    },

    init: function () {
        var newsLetter = this;
        $form.on("submit", function (e) {
            e.preventDefault();
            if (typeof isCaptchaValidated !== "undefined" && !isCaptchaValidated && typeof ssGoogleReCAPTCHA === "function") {
                ssGoogleReCAPTCHA("newsletter", this);
            } else {
                newsLetter.submit();
            }
        });

        try{
            $('#gender-subscription').blur(function() {
                $('#gender-subscription-error').text('Please select a gender');
            });
            $('button[name="home-email"').click(function() {
                setTimeout(function() {
                    $('#gender-subscription-error').text('Please select a gender');
                }, 300);        
            });
        }
        catch(er){

        }
    }
};

module.exports = newsletter;
