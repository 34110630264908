'use strict';

exports.init = function(){
	var selectors = window.components.Selectors;
	var toolTipSelector = selectors.components.tooltip;
	$(toolTipSelector.container).each(function(){
    	var $this = $(this);
    	var opt = {
                track: false,
                content: function () {
                    return $(this).find('.tooltip-content').html();
                }
            };
    	for(var key in toolTipSelector.directionClass){
    		if ($this.hasClass(toolTipSelector.directionClass[key])){
    			opt.tooltipClass = toolTipSelector.directionClass[key];
    			opt.position =  toolTipSelector.toolTipPosition[key];
    			opt.position.collision = "none";
    		}
    	}
    	opt.items = toolTipSelector.container;
    	$(document).tooltip(opt);
    });

    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });

    $(selectors.components.tooltip.formFieldTooltip).on('click', function(e) {
    	e.preventDefault();
    });
};
