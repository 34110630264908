'use strict';

var validator = require('../../../../../inherited/demandware/sitegenesis/app_storefront_core/cartridge/js/validator');

var auPhone = /^(\([0-9\.]*\))?[0-9\.\s]{1,14}$/;
var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
	alphanumeric : /^[a-zA-Z0-9 ]*$/,
	address : /^[a-zA-Z0-9\/\,\-\.\#\;\:\' ]*$/,
    city : /^[a-zA-Z\/\,\-\.\&\;\'\(\) ]*$/,
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
        au: auPhone,
        all: /^[0-9/(/)+-\s]{1,25}$/,
        softValidation: /^[0-9\-]{8,}$/
    },
    postal: {
    	au: /^([0-9]{4})$/,
    	others: /^([A-Z0-9]){1,10}$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    email: /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/
};

function removeWarningMessage (el) {
    var $warningMessage = $(el).siblings('span.warning');
    if ($warningMessage.length > 0) {
        $warningMessage.remove();
    }
}

function addWarningMessage (el, message) {
    var $warningMessage = $('<span/>', {
        class: 'warning',
        id: $(el).attr('id') + '-warning',
        text: message
    });

    $(el).after($warningMessage);
}

function initSoftValidation (el) {
    if ($(el).attr('id').indexOf('_shippingAddress_') > 0 || $(el).attr('id').indexOf('_billingAddress_') > 0) {

        $(el).off('blur').on('blur', function () {
            if (!$(this).val() || $(this).hasClass('error')) {
                return;
            }

            var rgx = regex.phone["softValidation"];
            var isValid = rgx.test($.trim($(this).val()));

            if (!isValid) {
                addWarningMessage($(this), Resources.PHONE_VALIDATION_MESSAGE);
            }
        });
    }
}

var validatePhone = function (value, el) {
    var rgx = regex.phone["all"];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    removeWarningMessage(el);
    if ($(el).hasClass('changed') && $(el).closest('form').hasClass('warning')) {
        $(el).removeClass('changed');
        $(el).closest('form').removeClass('warning');
    }

    if (isOptional || isValid) {
        initSoftValidation(el);
    }

    return isOptional || isValid;
};

var validateEmail = function(value, el){
	var isOptional = this.optional(el);
	return isOptional || regex.email.test($.trim(value));
}

var validateWithPleaseSelect = function(value, el){
	var lowerValue = value.toString().toLowerCase();
	return lowerValue != 'please-select' && lowerValue != 'please select...' && lowerValue != 'resource.pleaseselect';
}

function validatePostcode(value, el) {
	var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    if (rgx === void (0)) {
        rgx = regex.postal.others;
    }

    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
}

var validatePassword = function(value, el){
	var rules = $(el).rules();

	return value.length >= rules.minlength && value.length <= rules.maxlength;
}

var DHLRequire = function(value, el){
	var $form = $(el).closest('.address');
	var $selectedShipping = $form.find('[data-dhl-check]:checked, span[data-dhl-check]');
	if($selectedShipping.length){
		var name = $selectedShipping.data('dhl-check');
		if(name.indexOf('DHL') >= 0 && !value){
			return false;
		}
	}
	return true;
}

function validateAlphanumeric(value, el) {
     var isOptional = this.optional(el);
     var isValid = regex.alphanumeric.test($.trim(value));
     return isOptional || isValid;
}

function validateAddress(value, el) {
    var isOptional = this.optional(el);
    var isValid = regex.address.test($.trim(value));
    return isOptional || isValid;
}

function validateCity(value, el) {
    var isOptional = this.optional(el);
    var isValid = regex.city.test($.trim(value));
    return isOptional || isValid;
}

function isLeapYear(year) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

function validateDateRange(value, el) {
    var $elem = $(el);
    var maps = [4, 6, 9, 11];
    var isValid = true;

    var date = el.value.split('/'),
        year = parseInt(date[2], 10),
        month = parseInt(date[0], 10),
        day = parseInt(date[1], 10);

     if (!year && !month && !day) return true;
     if (!year || !month || !day) return false;

     if (month === 2) {
		 var leapYear = isLeapYear(year);

         if (leapYear && day > 29) {
             isValid = false;
         } else if (!leapYear && day > 28) isValid = false;
     } else if (maps.indexOf(month) !== -1 && day > 30) isValid = false;

    return isValid;
}

function validateGiftCertAmount (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 500);
    return isOptional || isValid;
}

$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

$.validator.addMethod('email', validateEmail, Resources.INVALID_EMAIL);

$.validator.addMethod('emailconfirm', validateEmail, Resources.INVALID_EMAIL);

$.validator.addMethod('postal', validatePostcode, Resources.INVALID_ZIP);

$.validator.addMethod('validate-password', validatePassword, SitePreferences.INVALID_PASSWORD);

$.validator.addMethod('vd-dhl-require', DHLRequire, Resources.VALIDATE_REQUIRED);

$.validator.addMethod("vd-alphanumeric", validateAlphanumeric, Resources.INVALID_ALPHANUMERIC);

$.validator.addMethod("vd-address", validateAddress, Resources.INVALID_ADDRESS);

$.validator.addMethod("vd-city", validateCity, Resources.INVALID_ADDRESS);

$.validator.addMethod('vd-date-range', validateDateRange, Resources.INVALID_DATE_RANGE);

$.validator.addMethod('gift-cert-amount', validateGiftCertAmount, Resources.GIFT_CERT_AMOUNT_INVALID);

$.validator.addMethod('vd-please-select', validateWithPleaseSelect, Resources.VALIDATE_REQUIRED);

$.validator.messages.required = function ($1, ele, $3) {
    removeWarningMessage(ele);
	var requiredText = ele.getAttribute("data-required-text") || $(ele).parents('.form-row').attr("data-required-text");
	return requiredText||Resources.VALIDATE_REQUIRED;
};

$.validator.setDefaults({
    ignore: ':hidden:not(.vd-date-range)'
});

validator.softValidatePhoneOnSubmit = function ($form) {
    var $phone = $form.find('input.phone');
    var rgx = regex.phone["softValidation"];
    var isValid = rgx.test($.trim($phone.val()));

    $phone.on("change paste keyup", function() {
        $phone.addClass('changed');
    });

    if (!$form.hasClass('warning') && !isValid) {
        removeWarningMessage($phone);
        addWarningMessage($phone, Resources.PHONE_VALIDATION_MESSAGE);
        $phone.siblings('span.warning').css('font-weight', 'bold');

        $('html, body').animate({
            scrollTop: $phone.offset().top
        }, 500);

        $form.addClass('warning');
        $phone.removeClass('changed');
        return false;
    }

    $form.removeClass('warning');
    return true;
};

module.exports = validator;
