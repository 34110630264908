var util = window.components.Util;

var currency = {
    init: function() {
        this.attachPriceConversion();
        this.attachPromoPriceConversion();
    },

    attachPriceConversion: function() {
        var $priceToConvert = $('.price-to-convert');

        if (typeof currencyConfig !== 'undefined' && !$.isEmptyObject(currencyConfig)) {
            var currentCurrencyCode = util.getCookie('currencycode');

            if (currentCurrencyCode != currencyConfig.defaultSiteCurrency) {
                $.each($priceToConvert, function(key, value) {
                    var priceValue = $(this).data('price-value');
                    var exchangeRate = isNaN(currencyConfig.currencies[currentCurrencyCode].exchangeRate) ? 1 : currencyConfig.currencies[currentCurrencyCode].exchangeRate;
                    var currencyPattern = currencyConfig.currencies[currentCurrencyCode].currencyPattern;
                    var currencyFormatterOptions = {
                        currency: currentCurrencyCode,
                        pattern: currencyPattern
                    };
                    var priceRange = {
                        min: $(this).data('price-range-min'),
                        max: $(this).data('price-range-max')
                    };
                    var priceDisplay = '';

                    if (!priceValue && priceRange.min && priceRange.max) {
                        priceDisplay = OSREC.CurrencyFormatter.format(priceRange.min * exchangeRate, currencyFormatterOptions) + ' - ' + OSREC.CurrencyFormatter.format(priceRange.max * exchangeRate, currencyFormatterOptions);
                    } else {
                        priceDisplay = OSREC.CurrencyFormatter.format(priceValue * exchangeRate, currencyFormatterOptions);
                    }

                    $(this).text(priceDisplay);
                });
            }
        }
        $priceToConvert.removeClass('hidden-price');
    },
    convertPriceToCurrency: function(obj) {
        var currentCurrencyCode = util.getCookie('currencycode');
        $.each(obj, function(key, value) {
            var priceValue = $(this).data('price-value');
            var exchangeRate = isNaN(currencyConfig.currencies[currentCurrencyCode].exchangeRate) ? 1 : currencyConfig.currencies[currentCurrencyCode].exchangeRate;
            var currencyPattern = currencyConfig.currencies[currentCurrencyCode].currencyPattern;
            var currencyFormatterOptions = {
                currency: currentCurrencyCode,
                pattern: currencyPattern
            };
            var priceRange = {
                min: $(this).data('price-range-min'),
                max: $(this).data('price-range-max')
            };
            var priceDisplay = '';

            if (!priceValue && priceRange.min && priceRange.max) {
                priceDisplay = OSREC.CurrencyFormatter.format(priceRange.min * exchangeRate, currencyFormatterOptions) + ' - ' + OSREC.CurrencyFormatter.format(priceRange.max * exchangeRate, currencyFormatterOptions);
            } else {
                priceDisplay = OSREC.CurrencyFormatter.format(priceValue * exchangeRate, currencyFormatterOptions);
            }

            $(this).text(priceDisplay);
        });
    },
    attachPromoPriceConversion: function() {
        var $promoPrices = $('[data-promo-price]');

        if (typeof currencyConfig !== 'undefined' && !$.isEmptyObject(currencyConfig)) {
            var currentCurrencyCode = util.getCookie('currencycode');

            if (currentCurrencyCode != currencyConfig.defaultSiteCurrency) {
                var exchangeRate = isNaN(currencyConfig.currencies[currentCurrencyCode].exchangeRate) ? 1 : currencyConfig.currencies[currentCurrencyCode].exchangeRate;
                var currencyPattern = currencyConfig.currencies[currentCurrencyCode].currencyPattern;
                var currencyFormatterOptions = {
                    currency: currentCurrencyCode,
                    pattern: currencyPattern
                };

                $promoPrices.each(function(key, value) {
                    var $promoPrice = $(this);
                    var basePriceValue = $promoPrice.data('promo-price');

                    if (isNaN(basePriceValue)) {
                        return;
                    }

                    var convertedPrice = OSREC.CurrencyFormatter.format(basePriceValue * exchangeRate, currencyFormatterOptions);
                    $promoPrice.text(convertedPrice);
                });
            }
        }

        $promoPrices.removeClass('hidden-promo-price');
    }
};

module.exports = currency;
