(function() {
    "use strict";
    var pluginName = 'numbericinput';
    var counter = 0;
    function numbericInputModule(options){
        var selectors = (options && options.selectorsModule) ? options.selectorsModule : window.components.Selectors,
        selectorsModule = selectors.components.numbericInput;
        
        var instance = {};

        instance.init = function () {
            registerNumbericInputOnSelector(selectorsModule.input);
        };

        instance.initContainer = function (containerselector) {
            registerNumbericInputOnSelector($(containerselector).find(selectorsModule.input));
        };

        var registerNumbericInputOnSelector = function (selector) {
            $(selector).each(function() {
                var $component = $(this);
                if ($component.hasClass("no-numberic")) {
                    return true;
                }
                var plugin = $component.data(pluginName);
                if(!plugin){
                	plugin = (function(){
                		$component.keydown(function (e) {
                			// Allow: backspace, delete, tab, escape, enter and .
                	        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                	             // Allow: Ctrl/cmd+A
                	            (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                	             // Allow: Ctrl/cmd+C
                	            (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                	             // Allow: Ctrl/cmd+X
                	            (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                	             // Allow: home, end, left, right
                	            (e.keyCode >= 35 && e.keyCode <= 39)) {
                	                 // let it happen, don't do anything
                	                 return;
                	        }
                	        // Ensure that it is a number and stop the keypress
                	        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                	            e.preventDefault();
                	        }
					    });
                		$component.change(function(e){
                			if($(this).val() == ''){
                				$(this).val(0);
                			}
                		});
                		return pluginName + (counter++);
                	})();
                	$component.data(pluginName, plugin);
                } 
            });
        }
        return instance;
    }

    module.exports = numbericInputModule;
})();