module.exports = function Geolocation() {
    var $geolocation = $("#geolocation");
    if ($geolocation.length < 1) {
        return;
    }

    var $body = $("body");
    var $closeButton = $geolocation.find(".geolocation__modal-header button");
    var $changeSiteButton = $geolocation.find(".geolocation-change-site");
    var $stayOnSiteLink = $geolocation.find(".geolocation-stay-on-site");

    var changeSiteURL = _.get(window, "data.components.geolocation.ChangeSiteURL");
    var hideGeoLocaleURL = _.get(window, "data.components.geolocation.HideGeoLocale");
    var requestInProgress = false;

    function open() {
        $geolocation.addClass("active");
        $body.addClass("scroll-locked");
    }

    function close() {
        $geolocation.removeClass("active");
        $body.removeClass("scroll-locked");
    }

    function changeSite(currencyCode, siteID) {
        if (!changeSiteURL || !siteID) {
            console.error("Cannot change site because request parameters are missing.");
            close();
            return;
        }

        if (requestInProgress) {
            console.warn(
                "One change site request is already in progress, please wait for it to complete."
            );
            return;
        }

        requestInProgress = $.ajax({
            dataType: "json",
            url: changeSiteURL,
            data: {
                format: "ajax",
                defaultSiteId: siteID,
                currencyCode: currencyCode || ""
            }
        });

        requestInProgress.done(function(data) {
            if (data && data.success) {
                close();
                location.reload(true);
            } else {
                console.error("Unexpected response recieved from Currency controller");
            }

            requestInProgress = undefined;
        });

        requestInProgress.fail(function() {
            console.error("Request to change site failed");
            close();
            requestInProgress = undefined;
        });
    }

    function hideGeoLocale() {
        if (requestInProgress) {
            console.warn(
                "One hide geolocale request is already in progress, please wait for it to complete."
            );
            return;
        }

        requestInProgress = $.ajax({
            type: "POST",
            dataType: "json",
            url: hideGeoLocaleURL,
            data: {
                format: "ajax"
            }
        });

        requestInProgress.done(function(data) {
            if (!data || !data.success) {
                console.error("Unexpected response recieved from Currency controller");
            }

            close();
            requestInProgress = undefined;
        });

        requestInProgress.fail(function() {
            console.error("Request to hide geolocale failed");
            close();
            requestInProgress = undefined;
        });
    }

    $closeButton.click(close);

    $changeSiteButton.on("click", function(e) {
        e.preventDefault();
        let changeSiteData = $(this).data("change-site");
        changeSite(changeSiteData.currencyCode, changeSiteData.siteId);
    });

    $stayOnSiteLink.on("click", function(e) {
        e.preventDefault();
       hideGeoLocale();
       close();
    });

    open();
};