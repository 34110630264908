function ProductCycler(element) {
    if (!element) {
        return;
    }

    const refs = $(element).refs();

    let currentPage = 0;
    let maxPage = 0;
    let itemsPerPage = 0;
    let count = 0;
    let firstIndex = 0; // Index of the first slide on the current page
    let lastIndex = 0; // and last slide on the page
    let autoCycleEnabled;
    let autoCycleDelay;
    let intervalTimer = null;
    let navigation;

    function show() {
        $(element).attr("data-visible", "");
    }

    /**
     * Update carousel styles to facilitate sliding between pages
     */
    function slide() {
        var offset = currentPage * 100;
        var width = 0;
        if (itemsPerPage > 0) {
            width = (100 / itemsPerPage) * count;
        }

        // Account for incomplete pages
        if (width > 100) {
            var delta = width - (offset + 100);

            if (delta <= 0) {
                offset += delta;
            }
        }

        // Centre products when single page
        if (count < itemsPerPage) {
            offset = (100 - width) / 2;
        } else {
            offset = -offset;
        }

        $(refs.slidesContainer).css("width", width + "%");
        $(refs.slidesContainer).css("margin-left", offset + "%");
        $(refs.slidesContainer).removeClass("slide-panel--dragging");
    }

    function setPage(pageNumber) {
        if (!pageNumber || pageNumber <= 0) {
            currentPage = 0;
        } else if (pageNumber >= maxPage) {
            currentPage = maxPage;
        } else {
            currentPage = pageNumber;
        }

        if (currentPage === maxPage && !autoCycleEnabled) {
            refs.next.disabled = true;
        } else {
            refs.next.disabled = false;
        }

        if (currentPage === 0 && !autoCycleEnabled) {
            refs.previous.disabled = true;
        } else {
            refs.previous.disabled = false;
        }

        firstIndex = itemsPerPage * currentPage;
        lastIndex = (itemsPerPage * (currentPage + 1)) - 1;

        if (lastIndex > count - 1) {
            lastIndex = count - 1;
            firstIndex = count - itemsPerPage;
        }

        if (firstIndex < 0) {
            firstIndex = 0;
        }

        if (navigation) {
            navigation.currentPage = currentPage;
        }

        slide();
        loadImages();

        if (autoCycleEnabled) {
            start();
        }
    }

    function previousPage() {
        if (currentPage > 0) {
            setPage((currentPage -= 1));
        } else {
            setPage(autoCycleEnabled ? maxPage : 0);
        }
    }

    function nextPage() {
        if (currentPage < maxPage) {
            setPage((currentPage += 1));
        } else {
            setPage(autoCycleEnabled ? 0 : maxPage);
        }
    }

    function start() {
        if (autoCycleEnabled && autoCycleDelay > 0) {
            clearTimeout(intervalTimer);
            intervalTimer = setTimeout(nextPage, autoCycleDelay);
        }
    }

    /**
     * This function is called whenever the product cycler is resized, so new itemsPerPage can be calculated
     * and re-rendered.
     *
     * It recalculates correct values based on new width.
     */
    function reset() {
        itemsPerPage = 2;

        var width = element.offsetWidth;

        if (width >= 750) {
            itemsPerPage = 3;
        }

        if (width >= 960) {
            itemsPerPage = 4;
        }

        if (width >= 1200) {
            itemsPerPage = 6;
        }

        maxPage = Math.ceil(count / itemsPerPage) - 1;

        if (navigation) {
            navigation.maxPage = maxPage;
            navigation.visible = itemsPerPage >= 2 && maxPage > 0;
        }

        if (currentPage > maxPage) {
            setPage(maxPage);
        } else {
            setPage(currentPage);
        }

        loadImages();
    }

    /**
     * Lazy load slides from the lazy-load url provided, and put them into main cycler container.
     */
    function loadSlides(url, callback) {
        $.ajax({
            url: url,
            method: "get",
            dataType: "html",
            success: function (data) {
                if (data) {
                    $(refs.slidesContainer).html(data);
                    callback();
                } else {
                    console.error(
                        "Product Cycler did not recieve any data from url:",
                        url
                    );
                }
            },
            error: function () {
                console.error("Failed to lazy load product cycler", element);
            }
        });
    }

    /**
     * Lazy load images currently visible.
     * Only works if product cycler is currently in view, and on current product cycler page;
     */
    function loadImages() {
        if (window.pageYOffset + window.innerHeight < element.offsetTop) {
            // product cycler not in view
            return;
        }

        var i;
        var slide;
        for (i = firstIndex; i <= lastIndex; i += 1) {
            slide = $(element).find("[data-slide-index='" + i + "']");

            if (slide.length > 0) {
                slide.find("[data-src]:not([src])").map(function () {
                    var image = $(this);
                    var src = image.data("src");

                    if (src) {
                        this.src = src;
                    }
                });
            }
        }
    }

    function init() {
        count = $(element).find("[data-slide]").length;

        if (count <= 0) {
            console.warn("Could not find any slides for", element);
            return;
        }

        var config = $(element).attr("data-config");
        if (config) {
            config = JSON.parse(config);
            autoCycleEnabled = !!config.autoCycleEnabled;
            autoCycleDelay = !isNaN(config.autoCycleDelay) ? config.autoCycleDelay : 5000;
        }

        // normal events:
        $(refs.next).click(nextPage);
        $(refs.previous).click(previousPage);

        $(element)
            .find("[data-product-image]")
            .on("error", function () {
                $(this).attr("data-error", "");
            });

        $(window).on(
            "resize",
            _.debounce(function () {
                reset();
            }, 250)
        );

        $(window).on(
            "scroll",
            _.debounce(function () {
                loadImages();
            }, 150)
        );

        // hammer events:
        var hammer = new Hammer(refs.carousel);

        hammer.on("swipeleft", function (event) {
            event.preventDefault();
            nextPage();
        });

        hammer.on("swiperight", function (event) {
            event.preventDefault();
            previousPage();
        });

        hammer.on("panstart", function () {
            $(refs.slidesContainer).addClass("slide-panel--dragging");
        });

        hammer.on("panend pancancel", function () {
            $(refs.slidesContainer).removeClass("slide-panel--dragging");
        });

        // vue components
        navigation = new Vue({
            el: refs.navigation,
            data: {
                visible: false,
                currentPage: currentPage,
                maxPage: maxPage
            },
            methods: {
                setPage: function (pageNumber) {
                    setPage(pageNumber);
                }
            }
        });

        show();
        reset();

        if (autoCycleEnabled) {
            start();
        }
    }

    // init:
    var slidesUrl = $(element).data("slides-url");
    if (slidesUrl) {
        loadSlides(slidesUrl, function () {
            init();
        });
    } else {
        init();
    }
}

/**
 * This function initializes all product cyclers found on the page at the time it is loaded.
 * Additionally, when the "ss-product-cycler:init" event is fired on the document,
 * it will find any non-initialized product cyclers on the page (lazy loaded) and initialize
 * them too.
 */
module.exports = (function () {
    var selector = "[data-module='product-cycler']";
    var cyclers = [];
    var elements = [];

    function init() {
        var element;

        // This is exponential, however amount of cyclers on the page should always be small
        $(selector).map(function () {
            element = this;

            // iterate over existing cyclers and check if it was already initialized
            if (elements.indexOf(element) < 0) {
                cyclers.push(new ProductCycler(element));
                elements.push(element);
            }
        });
        checkPrice();
    }

    function checkPrice() {
        try{
            var controls = $('.price-to-convert-recommended');
            window.components.Currency.convertPriceToCurrency(controls);
        }
        catch(er){
            console.log(er.message);
        }
    }

    init();

    // Pick up lazy cyclers
    $(document).on("ss-product-cycler:init", function () {
        init();
    });

    return cyclers;
}());
