const EVENT_VALUE = 0,
	  NON_INTERACTION = false,
	  EVENTS = {
		  facetedSearch: "facetedSearch",
		  resultsPageInteraction: "resultsPageInteraction",
		  brandPageInteraction: "brandPageInteraction",
		  selectStore: "selectStore",
		  trackVideo: "trackVideo",
		  trackBrandPageLinks: "trackBrandPageLinks",
		  searchFormSubmit: "searchFormSubmit",
		  colorVariantSelected: "colorVariantSelected",
		  addToCartButton: "addToCartButton",
		  showQuickview: "showQuickview",
		  addToCartButtonQuickview: "addToCartButtonQuickview"
	  };

var AnalyticEvent = {
	add: function(event) {
		if(!Array.isArray(window.dataLayer))
			window.dataLayer = [];

		window.dataLayer.push(event);
	},
	addFacetedSearch: function(category, action, label) {
		this.add({
			event: EVENTS.facetedSearch,
			eventCategory: category,
			eventAction: action,
			eventLabel: label || "",
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
    addResultsPageInteraction: function(category, action, label) {
		this.add({
			event: EVENTS.resultsPageInteraction,
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addBrandPageInteraction: function(action, label) {
		this.add({
			event: EVENTS.brandPageInteraction,
			eventCategory: AnalyticEvent.BRAND_PAGE_INTERACTION.eventCategory,
			eventAction: action,
			eventLabel: label,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addSelectStore: function(category) {
		this.add({
			event: EVENTS.selectStore,
			eventCategory: AnalyticEvent.SELECT_STORE.eventCategory + category,
			eventAction: AnalyticEvent.SELECT_STORE.eventAction,
			eventLabel: AnalyticEvent.SELECT_STORE.eventLabel,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addTrackVideo: function(action, label) {
		this.add({
			event: EVENTS.trackVideo,
			eventCategory: AnalyticEvent.TRACK_VIDEO.eventCategory,
			eventAction: action,
			eventLabel: label,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addTrackBrandPageLinks: function(action, label) {
		this.add({
			event: EVENTS.trackBrandPageLinks,
			eventCategory: AnalyticEvent.TRACK_BRAND_PAGE_LINKS.eventCategory,
			eventAction: action,
			eventLabel: label,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addSearchFormSubmit: function(label) {
		this.add({
			event: EVENTS.searchFormSubmit,
			eventCategory: AnalyticEvent.SEARCH_FORM_SUBMIT.eventCategory,
			eventAction: AnalyticEvent.SEARCH_FORM_SUBMIT.eventAction,
			eventLabel: label,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addColorVariantSelected: function(action, label) {
		this.add({
			event: EVENTS.colorVariantSelected,
			eventCategory: AnalyticEvent.COLOR_VARIANT_SELECTED.eventCategory,
			eventAction: action,
			eventLabel: label,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
	addAddToCartButton: function() {
		this.add({
			event: EVENTS.addToCartButton,
			eventCategory: AnalyticEvent.ADD_TO_CART_BUTTON.eventCategory,
			eventAction: AnalyticEvent.ADD_TO_CART_BUTTON.eventAction,
			eventLabel: AnalyticEvent.ADD_TO_CART_BUTTON.eventLabel,
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION
		});
	},
    showQuickview: function (quickViewGAData) {
        this.add({
            event: 'showQuickview',
            page: {
                pageType: 'quickview',
                pageSubtype: '',
                productPagePath: quickViewGAData.productPagePath
            },
            product: quickViewGAData.product
		});
    },
	addToCartButtonQuickview: function(quickViewAddToCartGAData) {
		this.add({
			event: EVENTS.addToCartButtonQuickview,
			eventCategory: 'button',
			eventAction: 'click',
			eventLabel: 'add to cart',
			eventValue: EVENT_VALUE,
			nonInteraction: NON_INTERACTION,
            page: {
                pageType: 'quickview',
                pageSubtype: '',
                productPagePath: quickViewAddToCartGAData.productPagePath
            },
            product: quickViewAddToCartGAData.product
		});
	}
};

AnalyticEvent.FACETED_SEARCH = {
	eventAction: {
		open: "Toggle Open",
		close: "Toggle Close",
		clearAll: "Clear All"
	},
	eventLabel: {
		check: "check",
		uncheck: "uncheck"
	}
};

AnalyticEvent.RESULTS_PAGE_INTERACTION = {
	eventCategory: {
		browse: "Browse - ",
		sortBy: "Sort By - ",
		refineBy: "Refine By - "
	},
	eventAction: {
		actions: {
			findOutMore: "Find out more - ",
			itemsPerPage: "Items Per Page - ",
			pageSelect: "Page Select - ",
			sortBy: "Sort By - ",
			navigation: "Navigation - "
		},
		events: {
			open: "Open",
			close: "Close",
			top: "Top",
			footer: "Footer",
			next: "Next",
			previous: "Previous"
		}
	}
};

AnalyticEvent.BRAND_PAGE_INTERACTION = {
	eventCategory: "Brand Banner",
	eventAction: "Shop "
};

AnalyticEvent.SELECT_STORE = {
	eventCategory: "Store Choice - ",
	eventAction: "click",
	eventLabel: "Regional Selector"
};

AnalyticEvent.TRACK_VIDEO = {
	eventCategory: "Vimeo",
	eventAction: {
		started: "Started video",
		paused: "Paused video",
		resumed: "Resumed video",
		completed: "Completed video"
	}
};

AnalyticEvent.TRACK_BRAND_PAGE_LINKS = {
	eventCategory: "Brand Page Links",
	eventAction: {
		menu: "Menu - brandsMenu",
		brandListPage: "Brand List Page",
		productDetailPage: "Product Detail Page",
		footer: "Footer"
	}
};

AnalyticEvent.SEARCH_FORM_SUBMIT = {
	eventCategory: "Search form",
	eventAction: "submit"
};

AnalyticEvent.COLOR_VARIANT_SELECTED = {
	eventCategory: "Color Variant Selected"
};

AnalyticEvent.ADD_TO_CART_BUTTON = {
	eventCategory: "button",
	eventAction: "click",
	eventLabel: "add to cart"
};

module.exports = AnalyticEvent;
